import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { appConfig } from 'src/app/_core/config/app-config.const';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../_services/auth.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'tangram-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss']
})
export class EmailConfirmationComponent implements OnInit {
  @ViewChild('AlertContent',  { static: true }) alertContent!: TemplateRef<any>;
  logoPath = appConfig.logoImg; // To store logo path
  token: string = ""; // To store token
  message: string = "Your email addresss is being verified."
  currentCommentId: string = "";
  alterMessage: string = "";
  userId = "";
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private modalService: NgbModal,
  ) {
    this.token = this.activatedRoute.snapshot.params['token'];
  }

  /**
   * Called when component being called
   */
  ngOnInit() {
    this.verifyEmail(); // verify email
  }

  /**
   * Verify email
   */
  verifyEmail() {
    this.authService.verifyEmail(this.token).subscribe((data: any) => {
      // if response is success then redirect to login page otherwise display message in modal
      if (!data.success) {
        this.showPopup(data.message, data.userId);
      } else {
        this.router.navigate(['login']);
      }
      this.message = "";
    }, () => {
      this.message = "";
    });
  }

  /**
   * Show message in sweet modal
   * @param message message to be displayed
   * @param userId userId to be used for sending email
   */
  showPopup(message, userId) {
    this.alterMessage = message
    this.userId = userId;
    this.modalService.open(this.alertContent, { centered: true, windowClass : 'unlock-premium' })
    // Swal.fire({
    //   html: message,
    //   icon: 'info',
    //   showCancelButton: true,
    //   confirmButtonText: 'Resend verification email',
    //   focusConfirm: false,
    //   cancelButtonText: 'OK',
    //   reverseButtons: true
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     this.resendVerificationEmail(userId);
    //   }
    // });
  }

  /**
   * Resend verification email
   * @param userId
   */
  resendVerificationEmail(userId: string) {
    this.authService.resendVerificationEmail(userId).subscribe((res: any) => {
      this.showPopup(res.message, userId);
    });
  }

  showMessageInSweetModal(){
    this.resendVerificationEmail(this.userId);
  }

}
