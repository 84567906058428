import { Component, ElementRef, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { CommonService } from 'src/app/_services/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tangram-newMember',
  templateUrl: './newMember.component.html',
  styleUrls: ['./newMember.component.scss']
})
export class NewMemberComponent implements OnInit {
  @ViewChild('AlertContent',  { static: true }) alertContent!: TemplateRef<any>;
  currentUser: any;
  registerdUser: any;
  alterMessage = ''
  constructor(
    private el: ElementRef,
    private authService: AuthService,
    private commonService: CommonService,
    private modalService: NgbModal,
    private router: Router) {
    this.currentUser = this.authService.currentUserValue;
  }

  ngOnInit() {
    this.registerdUser = JSON.parse(localStorage.getItem("tangramRegisteredUser"));
    if (!this.registerdUser && this.currentUser) {
      let firstName = this.currentUser.firstName ? this.currentUser.firstName.trim() : ''
      let lastName = this.currentUser.lastName ? this.currentUser.lastName.trim() : ''
      this.registerdUser = { 
        userId: this.currentUser._id, 
        name: firstName + ' ' + lastName
      };
      this.registerdUser.name = this.registerdUser ?this.registerdUser.name.trim() : '';
    }
  }

  /**
   * Redirect to url
   * @param url url to be redirected
   */
  redirect(url) {
    // Verify user
    this.verifyUser(url);
    // this.router.navigate([url]);
  }

  /**
   * Verify user
   * @param url url to be redirected
   */
  verifyUser(url) {
    if (this.registerdUser && this.registerdUser.userId) {
      // If user is verified then redirect to particular url
      this.authService.verifyUser(this.registerdUser.userId).subscribe((response: any) => {
        if (response.success) {
          localStorage.removeItem("tangramRegisteredUser");
          this.router.navigate([url]);
        } else {
          this.alterMessage = response.message
          this.modalService.open(this.alertContent, { centered: true, windowClass : 'unlock-premium' })
          Swal.fire({
            html: response.message,
            icon: 'info',
          });
        }
      });
    }else{
      this.router.navigate(['/login']);
    }
  }
}
