import { EventEmitter, Injectable, Renderer2 } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import videojs from 'video.js';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  // Set dashboard flag for footer componetn
  isDashboard: boolean;
  // receive flag from dashboard component
  receivedFlag: EventEmitter<boolean>;
  // maange sidemenu from bottom menu
  headerFooterComm: EventEmitter<any>;
  // show dashboard when click on bottom dashboard button
  showDashboard: EventEmitter<any>;
  scrollToUpSkill: EventEmitter<any>;
  openTangramFooterMenu: EventEmitter<any>;
  openSpotLightModal: EventEmitter<any>;
  addComment: EventEmitter<any>;
  isHomeRoute: EventEmitter<boolean>;
  onSocialLogin = new BehaviorSubject<string>("");
  onChangeView: EventEmitter<string>;
  openAskOrSearchQuestionModal: EventEmitter<boolean>;
  updateVideoContentConsumedPercentage: EventEmitter<any>;
  onGetPathway: EventEmitter<string>;
  chatStreamDataFromVideo: EventEmitter<any>;
  public onSocialLogin$ = this.onSocialLogin.asObservable();
  player: videojs.Player; // To store player information

  constructor(
    private router: Router
  ) {
    this.receivedFlag = new EventEmitter<boolean>();
    this.headerFooterComm = new EventEmitter<any>();
    this.showDashboard = new EventEmitter<any>();
    this.scrollToUpSkill = new EventEmitter<any>();
    this.openTangramFooterMenu = new EventEmitter<any>();
    this.openSpotLightModal = new EventEmitter<any>();
    this.addComment = new EventEmitter<any>();
    this.isHomeRoute = new EventEmitter<boolean>();
    this.onChangeView = new EventEmitter<string>();
    this.openAskOrSearchQuestionModal = new EventEmitter<boolean>();
    this.updateVideoContentConsumedPercentage = new EventEmitter<any>();
    this.onGetPathway = new EventEmitter<string>();
    this.chatStreamDataFromVideo = new EventEmitter<any>();
  }

  setVideoPlayer(player: any) {
    this.player = player;
  }

  /** check device is mobile or desktop */
  checkDeviceIsMObileOrNot() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // true for mobile device      
      return true;
    } else {
      // false for not mobile device
      return false;
    }
  }

  /** call function raise event for dashboard and footer
   * @param flag
   */
  raiseDashboardFooterEvent(flag: boolean): void {
    this.isDashboard = flag;
    this.receivedFlag.emit(flag);
  }

  /** call function when dashboard footer any action call
   * @param event to manage event
   * @param flag to manage sidemenu
   */
  raiseEventFoooter(event: Event, flag: boolean) {
    let eventData = {
      eventAction: event,
      flag: flag
    }
    this.headerFooterComm.emit(eventData);
  }

  /**
   * show dashboard when click on dashboard button
   * @param dashboard 
   */
  callDashboardEvent(dashboard: any) {
    this.showDashboard.emit(dashboard);
  }

  /** 
   * scroll to upskill div in mobile 
   */
  scrollToUpSkillDiv() {
    this.scrollToUpSkill.emit();
  }

  /**
   * open footer tangram menu
   * @param event 
   */
  callTangramFooterEvent(event) {
    this.openTangramFooterMenu.emit(event);
  }

  openSpotLightModalFooter(flag) {
    this.openSpotLightModal.emit(flag);
  }

  /**
   * Raise event to add comment
   * @param comment comment to be added
   */
  raiseEventToAddComment(comment: any) {
    this.addComment.emit(comment);
  }

  /**
   * check route is home or not
   * @param flag 
   */
  isHomeRouteCommon(flag: boolean) {
    this.isHomeRoute.emit(flag);
  }

  /**
  * Raise event to hide registration controls except screen name
  * @param userId to update screen name for social login
  */
  raiseEventToHideRegistrationControlsExceptScreenName(user: any) {
    this.onSocialLogin.next(user);
  }

  /**
   * Raise event to open ask or search question modal
   * @param isOpenModal isOpenModal to open modal
   */
  raiseEventToOpenAskOrSearchQuestionModal(isOpenModal: boolean) {
    this.openAskOrSearchQuestionModal.next(isOpenModal);
  }

  /**
   * Raise event to change view
   * @param viewName viewName to be changed
   */
  changeView(viewName: string) {
    this.onChangeView.next(viewName);
  }

  /**
   * Raise event to update video content consumed percentage
   * @param param data to be passed to show video update
   */
  raiseEventToUpdateVideoContentConsumedPercentage() {
    this.updateVideoContentConsumedPercentage.next();
  }

  /**
   * Raise event to get pathway in header and footer
   * @param pathwayId pathwayId to be got
   */
  raiseEventToGetPathwayInHeaderAndFooter(pathwayId: string) {
    this.onGetPathway.next(pathwayId);
  }

  /**
   * Load chat stream as per timeslot
   * @param data data to be update
   */
  loadChatStreamData(data) {
    this.chatStreamDataFromVideo.emit(data);
  }
  
  
}
