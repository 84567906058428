import { Injectable } from '@angular/core';
import { HttpClient,HttpParams  } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ConfigurationService {

  constructor(private http: HttpClient) {
  }

  /**
   * Get main questions
   */
  getMainQuestions() {
    return this.http.get(`${environment.baseUrl}/preferences/mainQuestion`);
  }

  /**
   * Get sub questions
   * @param mainQuestionId
   */
  getSubQuestions(mainQuestionId: string) {
    return this.http.get(`${environment.baseUrl}/preferences/parentQuestion/${mainQuestionId}`);
  }

  /**
   * Post sub questions
   * @param data data to be saved
   */
  postSubQuestions(data) {
    return this.http.post(`${environment.baseUrl}/preferences/storeQuestion`, data);
  }

  /**
   * Set profile and unfinished content
   * @param data data to be saved
   */
  setProfile(data) {
    return this.http.post(`${environment.baseUrl}/users/setProfile`, data);
  }

  /**
   * Get profile
   */
  getProfile() {
    return this.http.get(`${environment.baseUrl}/users/getProfile`);
  }

  /**
   * Get topics
   */
  getTopics() {
    return this.http.get(`${environment.baseUrl}/preferences/topics`);
  }

  /**
   * To retrive status of profile
   * @param userId to pass userId
   */
  getCompeleteSetUpStatus(userId: any) {
    return this.http.post(`${environment.baseUrl}/users/checkProfileStep`, userId);
  }

  doneProductOverview(data){
    return this.http.post(`${environment.baseUrl}/users/setProfile`, data);
  }

  /**
   * @returns Configurations related to subscription
   */
    getConfigurationsList() {
      let params = new HttpParams().set('interceptorNotNeeded', 'true');
      return this.http.get(`${environment.baseUrl}/admin/getConfigurations`, {params : params});
    }
}
