import { Component, OnInit, Input, Output, EventEmitter, ViewChild,TemplateRef } from '@angular/core';
import { appConfig } from 'src/app/_core/config/app-config.const';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard.service';
import { AuthService } from 'src/app/_services/auth.service';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'tangram-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.scss']
})
export class PlaylistComponent implements OnInit {
  @ViewChild('AlertContent',  { static: true }) alertContent!: TemplateRef<any>;
  iconPath = appConfig.iconPath;
  @Input() playlist: any;
  @Output() onChangePlaylist = new EventEmitter();
  @Output() deleteMediaFromPlayList = new EventEmitter();
  previewVideoThumbnail: string = "assets/img/videos/img6.png"; // To store image url
  currentUser: any;
  alterMessage : String = ''
  currentMedia : string = ''
  
  constructor(
    private dashboardService: DashboardService,
    private authService: AuthService,
    private modalService: NgbModal
  ) { 
    this.currentUser = this.authService.currentUserValue;
  }

  ngOnInit() {
  }
  onPlaylistClick(playlist,event) {
    const targetElement = event.target
    if(targetElement.classList.contains('delete-btn')){
      return;
    }
    this.onChangePlaylist.emit(playlist);
  }
  
  showPopup(mediaId) {
    this.currentMedia = mediaId
    this.alterMessage = "Do you want to delete this media ?"
    this.modalService.open(this.alertContent, { centered: true, windowClass : 'unlock-premium' })

  }

  deleteMedia(){
    this.deleteMediaFromPlayList.emit(this.currentMedia)
    this.currentMedia = ''
  }

}
