import {Location} from '@angular/common';
import { appConfig } from '../../../../_core/config/app-config.const';
import { Component, OnInit, Injectable, ElementRef, ViewChild, TemplateRef } from '@angular/core';
import { CreateTangramService } from './crearte-tangram.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ValidationService } from 'src/app/_core/services/validation.service';
import { Router, ActivatedRoute } from '@angular/router';
import { query } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/_services/auth.service';
import { HttpParams } from '@angular/common/http';
import initTrimSlider ,{slideOne, slideTwo, setDurationInit} from './trim-slider'
import { CommonService } from 'src/app/_services/common.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from 'src/app/modules/configuration/services/configuration.service';


declare var $: any;
declare let gtag: Function;

@Component({
  selector: 'tangram-create-tangram',
  templateUrl: './create-tangram.component.html',
  styleUrls: ['./create-tangram.component.scss']
})
export class CreateTangramComponent implements OnInit {
  @ViewChild('UnlockPremiumModalContent',  { static: true }) unlockPremiumModalContent!: TemplateRef<any>;
  iconPath: string = appConfig.iconPath; // To store icon path from config
  imgPath: string = appConfig.imagePath;
  videoForm: FormGroup; // To represent video form
  public startTime: any
  public endTime: any
  public duration: string = "00:00:00"
  public currentVideoDetail: any = { _id: '' }; // To store current video detail
  public trackVideoDetail = {}; // To store track video detail
  public isVideoFormSubmitted: boolean = false; // To store status of video form submission
  public totalVideoSeconds: number = 0; // To store total video seconds
  public startTimeErrorMessage: string = ""; // To store start time error message 
  public endTimeErrorMessage: string = ""; // To store end time error message
  public isSaveSubmit: boolean = false; // To store status of video form submission
  public youtubeId: string = ""; // To store Youtube video id
  public isYoutubeIdValid: boolean = false; // To store Youtube video id valid or not
  public videoDuration: any;
  public stratDuration:string;
  public currentUser : any
  imageChangedEvent: any = '';
  croppedImage: any = '';
  savedThumbImage: any = '';
  displayStyle = "none"; 
  iFrameURL:any;
  originalUrl: any;
  pathwayId: string = ""; // To store pathway id
  videoId: string = ""; // To store video id
  pageType: string = "create"; // To store page type
  /** pass content processed data to the component */
  currentUserSubscription: Subscription;
  isUserLoggedIn: boolean = false;
  redirectToLoginOrDashboard: any; // To store redirection url either login or dashboard
  clickGetInfo: Boolean = false; // To store the get info button click event 
  public platForm: String
  public contentProcessed: any = {
    data: 0
  };
  videoFormErrors = {
    pathwayId: '',
    videoType: '',
    youtubeVideoId: '',
    title: '',
    order: '',
    description: '',
    tagId: ''
  }; // To store errors
  videoFormValueChangesSubscription: Subscription;
  videoFormValidationMessages = { // To store pathway form validation
    'pathwayId': {
      'required': 'Pathway should be selected.',
    },
    'videoType': {
      'required': 'Type should be selected'
    },
    'youtubeVideoId': {
      'required': 'Youtube Video should not be empty.'
    },
    'order': {
      'required': 'Order should not be empty.'
    },
    'tagId': {
      'required': 'Tag should be selected.'
    }
  };
  maxBasicTangram: number = 0;
  maxBasicMedia: number = 0;
  maxPremiumTangram: number = 0;
  maxPremiumMedia: number = 0;
  isButtonDisabled: boolean = false;
  constructor(
    private location: Location,
    private authservice: AuthService,
    private createTangramService: CreateTangramService,
    private formBuilder: FormBuilder,
    private _validation: ValidationService,
    private router: Router, 
    private route: ActivatedRoute,
    private toasterService: ToastrService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private modalService: NgbModal,
    private configurationService: ConfigurationService,
  ) { 
    // build video form
    this.buildVideoForm();
    // check for page type
    this.handleFlow();
  }
  /**
   * fetching the title and description from URL 
   * @param url 
   * @returns title and description
   */
  getTitle(url: string) {
    return this.http.get(url, {responseType: 'text', params : {
        'interceptorNotNeeded' : 'true'
      }
    })
    .pipe(
      map(html => {
        const titleMatch = /<title>(.*?)<\/title>/i.exec(html);
        const descriptionMatch = /<meta\s+name="description"\s+content="(.*?)"\s*\/?>/i.exec(html);
        
        const title = titleMatch ? titleMatch[1] : '';
        const description = descriptionMatch ? descriptionMatch[1] : '';

        return { title, description };
      }),
      catchError(error => {
        // console.error('Error fetching website info:', error);
        // Throw a custom error with a status code
        const status = 500; // Use the existing status if available, or default to 500
        const customError = new Error(`Unable to retrieve website info (Status: ${status})`);
        customError['status'] = status; // Add a custom property to store the status code
        return throwError(customError);
      })
    );    
  }
  ngOnInit() {
    this.currentUserSubscription = this.authservice.currentUser.subscribe((currentUser) => {
      this.currentUser = currentUser;
      this.isUserLoggedIn = currentUser ? true : false;
      this.redirectToLoginOrDashboard = this.isUserLoggedIn ? '/dashboard' : '/login';
    });
    this.pageType == "create" && this.currentUser ? initTrimSlider(this.currentUser.subscriptionActive) : ''
    this.getConfigurations();

  }
  backButton() {
    this.location.back();
  }
  slideOne(){
    let startTime = slideOne()
    this.videoForm.patchValue({
      startTime: startTime
    })
  }
  slideTwo(){
    let endTime = slideTwo()
    this.videoForm.patchValue({
      endTime: endTime
    })
  }
  // Build video form
  buildVideoForm() {
    this.videoForm = this.formBuilder.group({
      _id: [null],
      pathwayId: [null],
      videoType: [null],
      youtubeVideoId: ['', Validators.required],
      title: [''],
      order: [''],
      description: [''],
      thumbnail: this.formBuilder.group({
        smallURL: [''],
        mediumURL: ['']
      }),
      duration: this.formBuilder.group({
        hour: [0],
        minute: [0],
        second: [0]
      }),
      tagId: [null],
      startTime: [null],
      endTime: [null],
      startTimeInSecond: [null],
      endTimeInSecond: [null],
    });
    this.getValidationErrors();

    // Get validation error when video form values get changed
    this.videoFormValueChangesSubscription = this.videoForm.valueChanges.subscribe(() => this.getValidationErrors());
  }


  // Get validation errors
  getValidationErrors() {
    this.videoFormErrors = this._validation.getValidationErrors(this.videoForm, this.videoFormValidationMessages);
  }

  /**
   * Called when video form gets submitted and another video can be saved 
   */
  saveAnotherVideo() {
    this.isSaveSubmit = false;
  }

  /**
   * Called when video form gets submitted and another video can be saved 
   */
  saveVideo() {
    this.isSaveSubmit = true;
  }

  /**
   * Called when video form gets submitted
   * @param isVideoFormValid isVideoFormValid to check whether video form is valid or not
   */
  onSubmit(isVideoFormValid: boolean) {
    let isValidate: boolean = false;
    if(this.clickGetInfo || this.pageType == 'edit'){
      isValidate = true
    }
    let startTimeInSecond;
    let endTimeInSecond;
    this.isVideoFormSubmitted = true; // Indicate that form is submitted

    const startTime = this.startTime;
    const endTime = this.endTime;
    this.totalVideoSeconds = this.convertTimeToSecond(this.videoForm.value.duration);
    if (startTime) {
      startTimeInSecond = this.convertTimeToSecond(startTime);
      if (startTimeInSecond >= this.totalVideoSeconds && this.platForm == "YouTube"){
        this.startTimeErrorMessage = "Start time should not be bigger that video duration.";
        isValidate = false;
      } else {
        this.startTimeErrorMessage = null;
      }
    } else {
      startTimeInSecond = this.startTimeValue();
    }

    if (endTime) {
      endTimeInSecond = this.convertTimeToSecond(endTime);
      if (endTimeInSecond > this.totalVideoSeconds && this.platForm == "YouTube") {
        this.endTimeErrorMessage = "End time should not be bigger than video duration.";
        isValidate = false;
      } else {
        this.endTimeErrorMessage = null;
      }
    } else {
      endTimeInSecond = this.endTimeValue();
    }


    if (!startTime && !endTime) {
      startTimeInSecond = this.startTimeValue();
      endTimeInSecond = this.endTimeValue();
    }

    this.videoForm.value.startTimeInSecond = startTimeInSecond;
    this.videoForm.value.endTimeInSecond = endTimeInSecond;

    if (isValidate) {
      if (startTimeInSecond > endTimeInSecond) {
        this.startTimeErrorMessage = "Start time should not be bigger than End time";
        isValidate = false;
      } else {
        this.startTimeErrorMessage = null;
      }
    }


    let tagId = this.isYoutubeIdValid ? 'Video' : '';
    let data = JSON.parse(JSON.stringify(this.videoForm.value));
    let placeHolderPath = ''
    data._id = this.videoId ? this.videoId : null;
    data.pathwayId = this.pathwayId;
    if( /\bhttps?:\/\/\S+\.(?:png|jpe?g|gif|bmp|ico|svg)\b/g.test(this.youtubeId) || this.youtubeId.includes('image')){
      tagId = 'Photo'
      data.thumbnail = { smallURL: this.youtubeId, mediumURL:  this.youtubeId }
    }
    if(this.youtubeId.includes('pdf')){
      placeHolderPath = 'assets/img/videos/PDF_Placeholder.png'
      tagId = 'PDF'
    }
    if(this.youtubeId.includes('spotify')){
      tagId = 'Audio'
    }
    if(this.youtubeId.includes('docs') || this.youtubeId.includes('drive')){
      placeHolderPath = 'assets/img/videos/Drive_Placeholder.png'
      tagId = 'Document'
    }
    data.tagId = tagId;
    if(data.tagId == ''){
      placeHolderPath = 'assets/img/videos/Blog_Articles_Placeholder.png'
    }
    if(placeHolderPath){
      data.thumbnail = { smallURL: placeHolderPath, mediumURL:  placeHolderPath }
    }
    data.videoType = '';
    if (this.platForm == 'Spotify') {
      data.songUrl = this.originalUrl;
    }
    data.youtubeVideoId = this.youtubeId;
    data.platForm = this.platForm
    if(this.savedThumbImage){
      data.thumbnail = { smallURL: this.savedThumbImage, mediumURL:  this.savedThumbImage }
    }
    if (isValidate) {
      if (isVideoFormValid) {
        this.createTangramService.getPathwayOrVideoCount(this.pathwayId).subscribe((response : any) => {
          if(!this.currentUser.subscriptionActive){
            if(!this.pathwayId && response.count >= this.maxBasicTangram ){
              this.openUnlockPremium(this.unlockPremiumModalContent)
              return;
            }else if(this.pathwayId && response.count >= this.maxBasicMedia){
              this.openUnlockPremium(this.unlockPremiumModalContent)
              return;
            }
          }
          this.createTangramService.saveVideo(data).subscribe((data: any) => {
            gtag('event', 'create_tangram', {
              'tangram_id': data.pathwayId,
            });
            this.resetVideoForm();
            if (this.isSaveSubmit) {
              this.router.navigate(['/share-tangram'], { queryParams: { pathwayId: data.pathwayId} });
            } else {
              this.router.navigate(['/create-tangram'], { queryParams: { pathwayId: data.pathwayId}, replaceUrl:true });
            }
          },(error) => {
            console.log('error: ', error);
          });
        })

      }
    }
  }


  /**
   * Reset video formiFrameURL
   */
  resetVideoForm() {
    this.videoForm.reset();
    this.videoForm.patchValue({
      duration: { hour: 0, minute: 0, second: 0 }
    })
    this.isVideoFormSubmitted = false;
    this.currentVideoDetail = {
      _id: ''
    }
    // this.originalUrl = '';
    this.duration = "00:00:00";
    this.videoDuration = null;
    this.stratDuration=null;
    this.iFrameURL = ''
    initTrimSlider(this.currentUser.subscriptionActive)
  }

  handlePaste(){
    this.resetVideoDetails()
  }
  
  resetVideoDetails(){
    const youtubeVideoIdValue = this.videoForm.get('youtubeVideoId').value;
    this.videoForm.reset({
      _id: null,
      pathwayId: null,
      videoType: null,
      youtubeVideoId: youtubeVideoIdValue, // Preserve the youtubeVideoId value
      title: '',
      order: '',
      description: '',
      thumbnail: {
        smallURL: '',
        mediumURL: ''
      },
      duration: {
        hour: 0,
        minute: 0,
        second: 0
      },
      tagId: null,
      startTime: null,
      endTime: null,
      startTimeInSecond: null,
      endTimeInSecond: null
    });
  }

  /**
   * 
   * @param ms milliseconds
   * @returns updated time format
   */
  convertMsToTimeObject = (ms: any) => {
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
  
    return {
      hour: hours % 24,
      minute: minutes % 60,
      second: seconds % 60,
    };
  };

  /**
   * Convert youtube duration to hour, minute and second
   * @param duration
   */
  youtubeDurationToHourMinuteSecond(duration) {
    // Format to store video duration
    let videoDuration = { hour: 0, minute: 0, second: 0 };

    // Remove PT text from the youtube duration
    duration = duration.replace("PT", '');

    // Get index of hour duration from youtube duration, if available then store it in hour
    let hourIndex = duration.indexOf('H');
    if (hourIndex != -1) {
      videoDuration.hour = parseInt(duration.substring(0, hourIndex));
    }

    // Get index of minute duration from youtube duration, if available then store it in minute
    let minuteIndex = duration.indexOf('M');
    if (minuteIndex != -1) {
      videoDuration.minute = parseInt(duration.substring(hourIndex + 1, minuteIndex));
    }

    // Get index of second duration from youtube duration, if available then store it in second
    let secondIndex = duration.indexOf('S');
    if (secondIndex != -1) {
      videoDuration.second = parseInt(duration.substring(minuteIndex + 1, secondIndex));
    }

    // Return video duration
    return videoDuration;
  }

  /**
   * Get detail's of public url
   */
  getPublicLinkDetails() {
    this.getYoutubeId();
    this.clickGetInfo = true;
    this.savedThumbImage = ''
    if(this.platForm != 'YouTube') {
      this.currentVideoDetail = {
        _id: ''
      }
    }
    
    if (this.platForm == 'YouTube') {
      this.getYoutubeVideoDetail(this.youtubeId);
    } else if (this.platForm == 'Spotify') {
      this.getSpotifyUrlInfo(this.youtubeId);
    }else if(!this.isYoutubeIdValid && this.platForm == 'Other'){
      this.videoForm.patchValue({
        title: '',
        description: ''
      })
      this.iFrameURL = this.getSafeUrl(this.youtubeId)
      // display title and description for image
      if( /\bhttps?:\/\/\S+\.(?:png|jpe?g|gif|bmp|ico)\b/g.test(this.youtubeId)){
        const urlParts = this.youtubeId.split("/");
        const filenameWithQueryParams = urlParts[urlParts.length - 1];
        const filenameWithoutQueryParams = filenameWithQueryParams.split("?")[0];
        const [imageName] = filenameWithoutQueryParams.split(".");
        this.videoForm.patchValue({
          title: imageName,
          description: ''
        })
      }
      // display title and description for workspace ex. document or spreadsheet
      else if(this.youtubeId.includes('document') || this.youtubeId.includes('spreadsheets') || this.youtubeId.includes('drive')){
        const urlParts = this.youtubeId.split("/");
        urlParts[urlParts.length - 1] = 'preview'
        let newURL = urlParts.join('/')
        this.iFrameURL = this.getSafeUrl(newURL)
        // TODO: temporary set the title and description for drive like this
        if( this.youtubeId.includes('drive')){
          this.getTitle(this.youtubeId).subscribe(
            (data) => {
              this.videoForm.patchValue({
                title: data.title,
                description: data.description
              })
            },
            (error) => {
              console.error('Error fetching website title:', error);
          });
        }else{
          this.getWorkSpaceInfo(this.youtubeId)
        }
      }
      // display title and description for articles
      else{
        let notAddProxy = ['wikipedia', 'axios', 'cbsnews'];
        let enCodeURL = encodeURIComponent(this.youtubeId)
        let newProxyURL = environment.proxyWorkerURL + '?id=1&interceptorNotNeeded=true&apiURL=' + enCodeURL;
        if(!environment.production){
          newProxyURL = environment.proxyWorkerURL + '?id=2&interceptorNotNeeded=true&apiURL=' + enCodeURL;
        }
        
        let notNeedProxy = notAddProxy.some((value) => this.youtubeId.includes(value))
        if(notNeedProxy){
          this.iFrameURL = this.getSafeUrl(this.youtubeId)
        }else{
          this.iFrameURL = this.getSafeUrl(newProxyURL)
        }
        
        // Get the title and description from proxyURL
        this.getTitle(newProxyURL).subscribe(
          (data) => {
            this.videoForm.patchValue({
              title: data.title,
              description: data.description
            })
          },
          (error) => {
            console.error('Error fetching website title:', error);
          }
        );
      }
      return true;
    }
  }

  /**
   * Get youtube video details
   * @param youtubeId youtube id
   * @
   */
  getYoutubeVideoDetail(youtubeId: string) {
    this.createTangramService.getYoutubeVideoDetail(youtubeId).subscribe((data: any) => {
      let title, description = "";
      let duration = { hour: 0, minute: 0, second: 0 };
      let initstart = { hour: 0, minute: 0, second: 0 };
      let thumbnail = { smallURL: '', mediumURL: '' };
      // Check video detail available or not
      if (data.items.length) {
        const [video] = data.items; // To get first video detail
        const { snippet, contentDetails } = video; // To get snippet and content details
        const { thumbnails } = snippet; // To get thumbnail details
        // Convert youtube duration to separate hour, minute and second
        duration = this.youtubeDurationToHourMinuteSecond(contentDetails.duration);
        title = snippet.title;
        description = snippet.description;
        thumbnail = { smallURL: thumbnails.default.url, mediumURL: thumbnails.medium.url }
        // Bind fetched detail(title, description, duration and thumbnail) into vidoe form to display
        this.videoForm.patchValue({
          title: title,
          description: description,
          duration: duration,
          thumbnail: thumbnail
        });
        this.videoDuration = this.convertTimeToSecond(duration)
        this.videoDuration = this.secondsToTime(this.videoDuration)
        this.stratDuration = "00:00:00"
        
        //handle current video
        this.handleCurrentVideo(true);
        setDurationInit(initstart, duration,this.pageType, {});
      } else {
        this.toasterService.error('Media does not exist');
        this.resetVideoForm();
      }
    })
  }

  /**
   * Get spotify url details
   * @param url spotify url
   * @
   */
  getSpotifyUrlInfo(url: string) {
    this.createTangramService.getSpotifyUrlDetail(url).subscribe((response: any) => {
      const data = response.data;
      let duration = { hour: 0, minute: 0, second: 0 };
      let title, description = "";
      let placeHolderPath = 'assets/img/videos/audio-placeholder.jpg';
      let thumbnail = { smallURL: placeHolderPath, mediumURL: placeHolderPath };
      // Check video detail available or not
      if (data) {
        title = data.title || "";
        description = data.description || "";
        duration = this.convertMsToTimeObject(data.duration_ms);
        if(data.thumbnail_url){
          thumbnail = { smallURL: data.thumbnail_url, mediumURL: data.thumbnail_url }
        }
        this.iFrameURL = this.getSafeUrl(data.iframe_url);
        this.originalUrl = data.iframe_url;
        // Bind fetched detail(title, description, duration and thumbnail) into vidoe form to display
        this.videoForm.patchValue({
          title: title,
          description: description,
          duration: duration,
          thumbnail: thumbnail
        });
        this.videoDuration = this.convertTimeToSecond(duration)
        this.videoDuration = this.secondsToTime(this.videoDuration)
        this.stratDuration = "00:00:00"
        
        //handle current video
        // this.handleCurrentVideo(true);
        // setDurationInit(initstart, duration,this.pageType)
      } else {
        this.toasterService.error('Media does not exist');
        this.resetVideoForm();
      }
    },(err) => {
      this.resetVideoForm();
    })
  }
//   isValidURL(url) {
//     // Basic URL validation
//     var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
//         '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
//         '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
//         '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
//         '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
//         '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
//     return pattern.test(url);
// }
  /**
   * Get workspace url details
   * @param url workspace url
   * @
   */
  getWorkSpaceInfo(url: string){
    let data = {
      id : '',
      workspaceName : ''
    }
    if(url.includes('document')){
      let documentId = '';
      const match = url.match(/\/document\/d\/([a-zA-Z0-9_-]+)\/(edit|view)?/);
      if (match && match[1]) {
        documentId = match[1];
      }
      data.id = documentId;
      data.workspaceName = 'document'
    }
    if(url.includes('spreadsheets')){
      let spreadSheetId = ''
      const match = url.match(/\/spreadsheets\/d\/([a-zA-Z0-9_-]+)\/(edit|view)?/);
      if (match && match[1]) {
        spreadSheetId = match[1];
      }
      data.id = spreadSheetId;
      data.workspaceName = 'spreadsheets'
    }
    // if(url.includes('file')){
    //   let spreadSheetId = ''
    //   const match = url.match(/\/file\/d\/([a-zA-Z0-9_-]+)\/(edit|view)?/);
    //   if (match && match[1]) {
    //     spreadSheetId = match[1];
    //   }
    //   data.id = spreadSheetId;
    //   data.workspaceName = 'file'
    // }
    
    if(data.id){
      this.createTangramService.getGoogleWorkspaceDetails(data).subscribe((response: any) => {
        if(response.data){
          if(response.data.title){
            this.videoForm.patchValue({
              title: response.data.title,
              description: ''
            })
          }else if(response.data.properties){
            this.videoForm.patchValue({
              title: response.data.properties.title,
              description: ''
            })
          }
        }
      })
    }
  }
  
  
  /**
   * Load video
   * @param initial to set initial values
   */
  handleCurrentVideo(initial) {
    this.currentVideoDetail = this.videoForm.value;
    this.currentVideoDetail._id = 123;
    this.currentVideoDetail.startTimeInSecond = 0;
    this.currentVideoDetail.endTimeInSecond = this.convertTimeToSecond(this.videoForm.value.duration);
    this.currentVideoDetail.videoURL = 'https://www.youtube.com/watch?v=' + this.currentVideoDetail.youtubeVideoId;
    if (initial) {
      this.startTimeValue();
      this.endTimeValue();
    }
    this.handleDuration();
  }

  /**
   * Load next video
   */
  loadNextVideo() {
    // Get current video position from playlist
  }
  /**
   * On playlist click
   * @param video video to be played
   */
  onPlaylistClick(video) {
    this.currentVideoDetail = video;
  }

  /**
   * Called when update consumed content
   * @param data data to be tracked
   */
  onUpdateConsumedContent(data) {
  }

  /**
 * Convert time to second
 * @param time time to be converted
 */
  convertTimeToSecond(time: any) {
    let timeInString = `${time.hour}:${time.minute}:${time.second}`;
    let splitTime = timeInString.split(':'); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    let seconds = (+splitTime[0]) * 60 * 60 + (+splitTime[1]) * 60 + (+splitTime[2]);
    return seconds;
  }

 /**
 * Convert secs to time string
 * @param secs to be converted
 */
  secondsToTime(secs)
  {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let str = (hours < 10 ? '0'+hours : hours) + ':' + (minutes < 10 ? '0'+minutes : minutes) + ':' + (seconds < 10 ? '0'+seconds : seconds);

    return str;
  }

  /**
   * set default starttime
   * @returns 0 when user not entered starttime
   */
  startTimeValue() {
    this.videoForm.patchValue({
      startTime: {
        hour: 0,
        minute: 0,
        second: 0
      }
    });
    return 0;
  }


  /**
   * set default video endtime
   * @returns total video seconds
   */
  endTimeValue() {
    this.videoForm.patchValue({
      endTime: this.currentVideoDetail.duration ? this.currentVideoDetail.duration : 
      {        
        hour: 0,
        minute: 0,
        second: 0
      }
    });
    return this.totalVideoSeconds;
  }

  /**
   * set duration of video trim
   */
  handleDuration() {
    let isValidate: boolean = true;
    let startTimeInSecond;
    let endTimeInSecond;
    const startTime = this.videoForm.value.startTime;
    const endTime = this.videoForm.value.endTime;
    this.totalVideoSeconds = this.convertTimeToSecond(this.videoForm.value.duration);
    if (startTime) {
      startTimeInSecond = this.convertTimeToSecond(startTime);
      if (startTimeInSecond >= this.totalVideoSeconds) {
        this.startTimeErrorMessage = "Start time should not be bigger that video duration.";
        isValidate = false;
      } else {
        this.startTimeErrorMessage = null;
      }
    }

    if (endTime) {
      endTimeInSecond = this.convertTimeToSecond(endTime);
      if (endTimeInSecond > this.totalVideoSeconds) {
        this.endTimeErrorMessage = "End time should not be bigger than video duration.";
        isValidate = false;
      } else {
        this.endTimeErrorMessage = null;
      }
    }

    if (isValidate) {
      if (startTimeInSecond > endTimeInSecond) {
        this.startTimeErrorMessage = "Start time should not be bigger than End time";
        isValidate = false;
      } else {
        this.startTimeErrorMessage = null;
      }
    }

    if (isValidate && endTimeInSecond && (startTimeInSecond || startTimeInSecond == 0) ) {
      let durationInsec = endTimeInSecond - startTimeInSecond;
      this.duration = this.secondsToTime(durationInsec);
    } else {
      this.duration = "00:00:00";
    }
  }

  /**
   * Get video details from video id
   */
  getVideoDetail() {
    this.createTangramService.getVideoDetail(this.videoId).subscribe((data: any) => {
      const { _id, youtubeVideoId, pathwayId, title, description, thumbnail, duration, tagId, startTime, endTime, startTimeInSecond, endTimeInSecond, platForm, videoURL } = data.data;
      this.videoForm.patchValue({
        _id: _id,
        pathwayId: pathwayId,
        videoType: '',
        youtubeVideoId: youtubeVideoId,
        title: title,
        order: '',
        description: description,
        thumbnail: thumbnail,
        duration: duration,
        tagId: tagId,
        startTime: startTime,
        endTime: endTime,
        startTimeInSecond: startTimeInSecond,
        endTimeInSecond: endTimeInSecond,
      });
      this.originalUrl = videoURL;
      this.pathwayId = pathwayId;
      this.getYoutubeId();
      this.iFrameURL = this.getSafeUrl(videoURL)
      if(platForm == 'Other'){
        if(videoURL.includes('spreadsheets') || videoURL.includes('document') || videoURL.includes('drive')){
          const urlParts = videoURL.split('/');
          urlParts[urlParts.length - 1] = 'preview';
          let iframeURL = urlParts.join('/');
          console.log('iframeURL: ', iframeURL);
          this.iFrameURL = this.getSafeUrl(iframeURL)
        }else{
          let enCodeURL = encodeURIComponent(videoURL)        
          let newProxyURL = environment.proxyWorkerURL + '?id=1&interceptorNotNeeded=true&apiURL=' + enCodeURL;
          if(!environment.production){
            newProxyURL = environment.proxyWorkerURL + '?id=2&interceptorNotNeeded=true&apiURL=' + enCodeURL;
          }
          this.iFrameURL = this.getSafeUrl(newProxyURL)
        }
      }
      this.platForm = platForm
      if(platForm == "YouTube"){
        this.isYoutubeIdValid = true;
        this.handleCurrentVideo(false);
      }
      this.videoDuration = this.convertTimeToSecond(duration)
      this.videoDuration = this.secondsToTime(this.videoDuration)
      this.stratDuration = "00:00:00"
      if(this.isYoutubeIdValid){
        setDurationInit(startTime,endTime,this.pageType,duration)
      }
    },
    (err) => {
      this.toasterService.error('Media does not exist');
      this.router.navigate(['/home']);
    });
  }

  /**
   * Handle module flow based on route
   */
  handleFlow() {
    if (this.route.snapshot.routeConfig.path == 'edit-tangram-video/:videoId') {
      this.pageType = 'edit';
      this.route.params.subscribe((param) => {
        if (param && param.videoId) {
          this.videoId = param.videoId;
          this.getVideoDetail();
        } else {
          this.toasterService.error('Media does not exist');
          this.router.navigate(['/home']);
        }
      })
    } else if (this.route.snapshot.routeConfig.path == 'create-tangram') {
      this.pageType = 'create';
      this.route.queryParams
        .subscribe(params => {
          this.pathwayId = params.pathwayId ? params.pathwayId : '';
        }
      );
    }
  }

  /**
   * Fetch Youtube video id from input string
   */
  getYoutubeId() {
    let strVal = this.videoForm.value.youtubeVideoId;
    let id = strVal;
    try {
      this.isYoutubeIdValid = true;
      if (strVal.includes('youtu.be')) {
        let url = (new URL(strVal));
        id = url.pathname.slice(1);
        this.platForm = 'YouTube'
      } else if (strVal.includes('youtube.com')) {
        let url = (new URL(strVal));
        let search = new HttpParams({ fromString: url.search.slice(1) });
        this.platForm = 'YouTube'
        id = search.get('v');
      }else if (strVal.includes('spotify')) {
        this.platForm = 'Spotify';
        this.isYoutubeIdValid = false;
      } else{
        this.platForm = 'Other'
        this.isYoutubeIdValid = false;
      }
      this.youtubeId = id;
    } catch(e) {
      this.isYoutubeIdValid = false;
      this.toasterService.error('Media does not exist');
      this.resetVideoForm();
    }
  }
  focusInputYoutube(){
    if(!this.isUserLoggedIn){
      this.router.navigate(['/login'],{queryParams : {returnUrl: '/create-tangram'}});
    }
  }
  getSafeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  getInput(event){
    this.clickGetInfo = false
    if(!event.target.value && this.pageType == 'create'){
      this.resetVideoForm()
    }
  }
  showHyperlinkModal(){
    $("#hyperlinkModal").appendTo('body').modal('show');
  }
  
  openModal() {
    this.displayStyle = 'block';
  }

  closeModal() {
    this.displayStyle = 'none';
    const fileInput: HTMLInputElement = document.getElementById('fileInput') as HTMLInputElement;
    if(fileInput){
      fileInput.value = null
    }
  }
  saveCroppedImage(){
    this.savedThumbImage = this.croppedImage
    this.closeModal()
  }
  undoThumbnail(){
    this.savedThumbImage = ''
  }

  fileChangeEvent(event: any): void {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      const fileSize: number = file.size; // File size in bytes
      const maxSize: number = 2 * 1024 * 1024; // 2MB in bytes

      // Check file type
      const fileType: string = file.type;
      if (fileType !== 'image/png' && fileType !== 'image/jpeg' ) {
        this.toasterService.error('Only PNG and JPEG files are allowed.');
        event.target.value = ''; // Reset the input field to clear the selected file
        return;
      }
      
      // Check file size
      if (fileSize > maxSize) {
        this.toasterService.error('File size exceeds 2MB. Please select a smaller file.')
        event.target.value = ''; // Reset the input field to clear the selected file
        return;
      }

    }
    this.imageChangedEvent = event;
    this.openModal()
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  openUnlockPremium(modalContent: TemplateRef<any>){
    this.modalService.open(modalContent, { centered: true, windowClass : 'unlock-premium' })
  }

  navigateToPricing(){
    this.modalService.dismissAll()
    this.router.navigate(['/home/payment-page']);
  }

  getConfigurations() {
    // Get pathway list by using users service
    this.configurationService.getConfigurationsList().subscribe((res: any) => {
      if (res.data.length) {
        let data = res.data[0];
        this.maxBasicTangram = data.maxBasicTangram;
        this.maxBasicMedia = data.maxBasicMedia;
        this.maxPremiumTangram = data.maxPremiumTangram;
        this.maxPremiumMedia = data.maxPremiumMedia;
      }
    });
  }

}
