import { Router, ActivatedRoute } from '@angular/router';
import { appConfig } from 'src/app/_core/config/app-config.const';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { ToastrService } from 'ngx-toastr';
import { TinyUrlService } from '../../services/tinyurl.service';


@Component({
  selector: 'share-tangram',
  templateUrl: './share-tangram.component.html',
  styleUrls: ['./share-tangram.component.scss']
})
export class ShareTangramComponent implements OnInit {
  imgPath = appConfig.imagePath;
  iconPath = appConfig.iconPath;
  pathwayId: string = "";
  whatsAppShareUrl: string = '';
  fbShareUrl: string = '';
  shareUrl: any = '';
  publicUrl: string = "";
  isIOs : boolean = false;
  private ngNavigatorShareService: NgNavigatorShareService;

  constructor(
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    ngNavigatorShareService: NgNavigatorShareService,
    private toasterService: ToastrService,
    private tinyUrlService: TinyUrlService,

  ) { 
    this.ngNavigatorShareService = ngNavigatorShareService;
  }
  

  async ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.pathwayId = params.pathwayId;
        this.publicUrl = environment.appRedirectUrl + '/#/dashboard/' + this.pathwayId
      }
    );
    this.fbShareUrl = environment.fbShareUrl;
    this.shareUrl = await this.shortenUrlFunc(this.publicUrl)
    // this.shareUrl = this.publicUrl;
    this.isIOs = /iphone|ipad/i.test(navigator.userAgent);
    
    let details = navigator.userAgent;

    let regexp = /android|iphone|kindle|ipad/i;
    let isMobileDevice = regexp.test(details);

    if (isMobileDevice) {
      this.whatsAppShareUrl=environment.whatsAppShareUrlMobile;
    } else {
      this.whatsAppShareUrl=environment.whatsAppShareUrl;
    }
  }
  
  copyToClipboard(event) {
    let element : any  = document.getElementById("share-tangram-url");
    // let elementText = element.getAttribute('value');
    // this.copyText(this.publicUrl);
    this.copyText(element.value);
    this.toasterService.success('Link copied to clipboard');
  }
  
  copyText(text) {
    navigator.clipboard.writeText(text);
  }

  backButton() {
    // this.location.back();
    this.router.navigate(['/dashboard', this.pathwayId]);
  }
  
  /**
 * redirects user to share popup
 * @param link to be shared
 */
  shareRedirect(appUrl,link) {
    var shareURL = appUrl ? appUrl + encodeURIComponent(link) : link;
    window.open(shareURL,'popup','width=600,height=600'); 
    return false;
  }

  shareRedirectWithIOs(shareUrl){
    this.ngNavigatorShareService.share({
      title: '',
      // text: 'Sharing My tangram',
      url: shareUrl
    }).then( (response) => {
      console.log(response);
    })
    .catch( (error) => {
      console.log(error);
    })  
  }
  async shortenUrlFunc(longUrl) {
    try {
      const response: any = await this.tinyUrlService.shortenUrl(longUrl).toPromise();
      return response.data.tiny_url; // Return the shortened URL
    } catch (error) {
      console.error('Error occurred while shortening URL:', error);
        this.toasterService.error(error)
        return this.publicUrl
    }
  }
}
