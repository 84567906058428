import { Component, OnChanges, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/_services/common.service';
import { AuthService } from '../../_services/auth.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DownloadModalComponent } from 'src/app/shared/components/download-modal/download-modal.component';
import { appConfig } from 'src/app/_core/config/app-config.const';
import { environment } from 'src/environments/environment';
import { CommonModalComponent } from 'src/app/shared/components/common-modal/common-modal.component';
import { NgNavigatorShareService } from 'ng-navigator-share';
declare let gtag: Function;
@Component({
  selector: 'tangram-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],

})
export class FooterComponent implements OnInit, OnDestroy {
  // mobile footer menu
  currentYear: any;
  mobileFooterMenu: any;
  bottomBtnClicked: boolean = false;
  isSubscriptionActive: boolean;
  showSideMenu: boolean = false;
  ratingBtnClicked: boolean = false;
  clicked: boolean;
  receivedFlagSubScription: Subscription;
  isUserLoggedIn: boolean = false; // To check whether user is logged in or not
  selectedTab: string = "dashboard"; // To set active class based on current selected tab
  currentUserSubscription: Subscription;
  iconPath = appConfig.iconPath;
  isHomePageSubScription: Subscription;
  showUpskillTab: boolean = true; // To show/hide upskill tab
  showLeaderboardTab: boolean = false; // To show/hide leaderboard tab
  showAskOrSearchTab: boolean = false; // To show/hide ask or search tab
  showTangramForumTab: boolean = true; // To show/hide tangram forum tab
  isForumPage: boolean = false;
  isDashboard: boolean = false;
  isHomePage: boolean = false;
  isQuestionPage: boolean = false;
  showAddCommentTab: boolean = false;
  onPageChangeSubScription: Subscription;
  isLeaderBoard: boolean = false;
  isAccountMgmtPage: boolean = false;
  isMyTangram: boolean = true;
  pathwayId: string = ""; // To store pathway id
  getPathwaySubscription: Subscription;
  shareUrl: string = 'https://staging.tangramio.com';
  fbShareUrl: string = '';
  inAppShareUrl:string = '';
  whatsAppShareUrl: string = '';
  whatsAppShareUrl1: string = '';
  isGlobalSharePopupOpen: boolean = false;
  isMobileDevice: boolean = false;
  private ngNavigatorShareService: NgNavigatorShareService;
  constructor(
    private commonService: CommonService,
    private authservice: AuthService,
    private modalService: NgbModal,
    ngNavigatorShareService: NgNavigatorShareService,
    private router: Router) {
    this.ngNavigatorShareService = ngNavigatorShareService;
    this.receivedFlagSubScription = this.commonService.receivedFlag.subscribe((param: boolean) => {
      this.isDashboard = param;
      console.log('param: ', param);
      if (this.isDashboard) {
        this.isQuestionPage = false;
        this.isHomePage = false;
        this.isForumPage = false;
        this.isLeaderBoard = false;
        this.isAccountMgmtPage = false;
      }
    });

    this.currentUserSubscription = this.authservice.currentUser.subscribe((currentUser) => {
      this.isUserLoggedIn = currentUser ? true : false;
    });

    this.isHomePageSubScription = this.commonService.isHomeRoute.subscribe((param: boolean) => {
      this.isHomePage = param;
      if (this.isHomePage) {
        this.isQuestionPage = false;
        this.isForumPage = false;
        this.isDashboard = false;
        this.isLeaderBoard = false;
        this.isAccountMgmtPage = false;
      }
    });
    this.onPageChangeSubScription = this.commonService.onChangeView.subscribe((param: any) => {
      if (param == "question-detail") {
        this.isQuestionPage = true;
        this.isHomePage = false;
        this.isDashboard = false;
        this.isForumPage = false;
        this.isLeaderBoard = false;
        this.isAccountMgmtPage = false;
      }
      if (param == "forum") {
        this.isQuestionPage = false;
        this.isHomePage = false;
        this.isDashboard = false;
        this.isForumPage = true;
        this.isLeaderBoard = false;
        this.isAccountMgmtPage = false;
      }
      if (param == "leaderboard") {
        this.isQuestionPage = false;
        this.isHomePage = false;
        this.isDashboard = false;
        this.isForumPage = false;
        this.isLeaderBoard = true;
        this.isAccountMgmtPage = false;
      }
      if (param == "account-management") {
        this.isQuestionPage = false;
        this.isHomePage = false;
        this.isDashboard = false;
        this.isForumPage = false;
        this.isLeaderBoard = false;
        this.isAccountMgmtPage = true;
      }
    });
  }

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem('currentUser'));
    if (user) {
      this.isSubscriptionActive = user.subscriptionActive
    }
    // this.initializeMobileMenu();
    // Bind pathway id
    this.pathwayId = localStorage.getItem("pathwayId");
    this.getPathwaySubscription = this.commonService.onGetPathway.subscribe((pathwayId) => {
      if (pathwayId) {
        this.pathwayId = pathwayId;
      }
    });
    this.currentYear = new Date().getFullYear();
    this.fbShareUrl = environment.fbShareUrl;
    let details = navigator.userAgent;
    this.inAppShareUrl = environment.inAppShareUrl;
    let regexp = /android|iphone|kindle|ipad/i;

    /* Using test() method to search regexp in details
    it returns boolean value*/
    this.isMobileDevice = regexp.test(details);
    if (this.isMobileDevice) {
      this.whatsAppShareUrl=environment.whatsAppShareUrlMobile;
    } else {
      this.whatsAppShareUrl=environment.whatsAppShareUrl;
    }
  }


  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    this.receivedFlagSubScription ? this.receivedFlagSubScription.unsubscribe() : '';
    this.currentUserSubscription ? this.currentUserSubscription.unsubscribe() : '';
    this.isHomePageSubScription ? this.isHomePageSubScription.unsubscribe() : '';
    this.onPageChangeSubScription ? this.onPageChangeSubScription.unsubscribe() : '';
    if (this.getPathwaySubscription) {
      this.getPathwaySubscription.unsubscribe();
    }
  }

  /**
   * scroll to upskill div on upskill button menu
   * @param tabName current selected tab name
   */
  goToUpskill(tabName: string) {
    let user = JSON.parse(localStorage.getItem('currentUser'));
    if (user) {
      gtag('event', 'page_visit', {
        'event_category': user._id,
        'event_label': this.router.url
      });
    }
    this.selectedTab = tabName;
    this.bottomBtnClicked = true;
    this.commonService.scrollToUpSkillDiv();
  }

  /** initialize mobile footer menu */
  initializeMobileMenu() {
    this.mobileFooterMenu = [
      { value: "dashboard", name: 'Dashboard', img: 'db.svg', pageToShow: 'All', condition: 'isUserLoggedIn', action: "goToDashboard('dashboard')" },
      { value: "downloadApp", name: 'Download App', img: 'download-app.svg', pageToShow: '', condition: 'isHomePage && !isUserLoggedIn', action: "openDownloadAppModal()" },
      { value: "inviteReferral", name: 'Invite/Referral', img: 'share.svg', pageToShow: '', condition: 'isHomePage', action: "" },
      { value: "signUp", name: 'Sign Up', img: 'signup.svg', pageToShow: '', condition: 'isHomePage && !isUserLoggedIn', action: "" },
      { value: "logout", name: 'Logout', img: 'logout.svg', pageToShow: '', condition: 'isHomePage && isUserLoggedIn', action: "logout()" },
      { value: "upskill", name: 'Upskill', img: 'skill-development.svg', pageToShow: '', condition: '(isDashboard && !isHomePage) && showUpskillTab', action: "goToUpskill('upskill')" },
      { value: "tangramForum", name: 'Tangram Forum', img: 'tangram-forum.svg', pageToShow: '', condition: '(isDashboard && !isHomePage) && showTangramForumTab', action: "selectedTab='forum'" },
      { value: "tangram", name: 'Tangram', img: 'tangram-symbol.svg', pageToShow: '', condition: '!isDashboard && !isHomePage', action: "openTangramRating($event, 'tangramButton')", class: 'tangram-btn' },
      { value: "forum", name: 'Forum', img: 'tangram-forum.svg', pageToShow: '', condition: '!isDashboard && !isHomePage', action: "" },
      { value: "leaderboard", name: 'Leaderboard', img: 'leaderboard.svg', pageToShow: '', condition: '(!isDashboard || showLeaderboardTab) && !isHomePage', action: "" },
      { value: "askOrSearch", name: 'Ask or Search', img: 'ask-question.svg', pageToShow: '', condition: '(!isDashboard || showLeaderboardTab) && !isHomePage', action: "" },
      { value: "menu", name: 'Menu', img: 'hamburge.svg', pageToShow: '', action: "openSideMenu($event, 'menu')" },
    ];
  }

  /**
   * open side menu on bottom button in mobile
   * @param event
   * @param tabName current selected tab name
   */
  openSideMenu(event, tabName: string) {
    this.selectedTab = tabName;
    this.commonService.raiseEventFoooter(event, false);
  }

  /**
   * show dashboard in mobile
   * @param tabName current selected tab name
   */
  goToDashboard(tabName: string) {
    let user = JSON.parse(localStorage.getItem('currentUser'));
    if (user) {
      gtag('event', 'page_visit', {
        'event_category': user._id,
        'event_label': this.router.url
      });
    }
    this.selectedTab = tabName;
    this.router.navigate(['/create-tangram']);
    // this.changeView('dashboardOverview');
    this.commonService.callDashboardEvent(true);
  }

  /**
   * open footer rating menu
   * @param event
   * @param tabName current selected tab name
   */
  openTangramRating(event, tabName: string) {
    let params = {
      event: event,
      flag: this.ratingBtnClicked
    };
    this.selectedTab = tabName;
    this.commonService.callTangramFooterEvent(params);
  }

  /**
   * Open download app modal
   */
  openDownloadAppModal() {
    const modalRef = this.modalService.open(DownloadModalComponent, { centered: true, windowClass: 'modal-small' });
  }

  /**
   * Change view
   * @param viewName viewName to be changed
   */
  changeView(viewName) {
    switch (viewName) {
      case 'forum':
        this.showTangramForumTab = false;
        this.showUpskillTab = false;
        this.showLeaderboardTab = true;
        this.showAskOrSearchTab = true;
        this.showAddCommentTab = false;
        break;
      case 'dashboard':
        this.showTangramForumTab = true;
        this.showUpskillTab = true;
        this.showLeaderboardTab = false;
        this.showAskOrSearchTab = false;
        this.showAddCommentTab = false;
        break;
      case 'question-detail':
        this.showTangramForumTab = false;
        this.showUpskillTab = false;
        this.showLeaderboardTab = true;
        this.showAskOrSearchTab = false;
        this.showAddCommentTab = true;
      default:
    }
    this.commonService.changeView(viewName);
  }

  /**
   * Called when click on ask or search tab
   */
  onClickAskOrSearchTab() {
    this.commonService.raiseEventToOpenAskOrSearchQuestionModal(true);
  }

  //logout user
  logout() {
    this.authservice.logout();
    this.router.navigate(["/home"]);
  }

  copyLink(type){
    // const content = document.getElementById('share-tangram-url').textContent;
    // loading the content into our clipboard
    let link = this.shareUrl;
    navigator.clipboard.writeText(link);
  }
  
  shareRedirect(appUrl,link) {
    var shareURL = appUrl + encodeURIComponent(link);
    window.open(shareURL,'popup','width=600,height=600'); 
    return false;
  }

  openSharePopup() {
    // if(this.CurrentSharePathwayId && this.CurrentSharePathwayId !== pathwayId) {
      var popup = document.getElementById("global-share-popup");
      var btnPopup = document.getElementById("global-shareBtn");
      // this.isGlobalSharePopupOpen = true;
      if(popup) {
        popup.classList.toggle("show");
      }
      if(btnPopup) {
        btnPopup.classList.toggle("visible");
      }
    // }
    var popup = document.getElementById("global-share-popup");
    var btnPopup = document.getElementById("global-shareBtn");
    let isIOS = /iphone|ipad/i.test(navigator.userAgent);
    if(isIOS){
      this.ngNavigatorShareService.share({
        title: '',
        // text: 'Sharing My tangram',
        url: this.shareUrl
      }).then( (response) => {
        console.log(response);
      })
      .catch( (error) => {
        console.log(error);
      })    
    }else{
      if(popup) {
        popup.classList.toggle("show");
      }
      if(btnPopup) {
        btnPopup.classList.toggle("visible");
      }
    }
  }

  /** 
   * Open invite referrel popup
   */
  openShareModal() {
    const modalRef = this.modalService.open(CommonModalComponent, { centered: true });
    modalRef.componentInstance.data = "Coming Soon: Ability to invite others to the community!";
  }
}
