import { Component, OnInit, Input, OnChanges, OnDestroy, ElementRef, ViewChild, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DashboardService } from '../../services/dashboard.service';
import { Subscription } from 'rxjs';
import { CommonService } from '../../../../_services/common.service';
import { appConfig } from 'src/app/_core/config/app-config.const';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FlagConfirmationModalComponent } from '../../../../shared/components/flag-confirmation-modal/flag-confirmation-modal.component';
import { ShowProfileModalComponent } from 'src/app/shared/components/show-profile-modal/show-profile-modal.component';
import { AuthService } from 'src/app/_services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

declare let gtag: Function;
declare var $: any;
@Component({
  selector: 'tangram-chat-stream',
  templateUrl: './chat-stream.component.html',
  styleUrls: ['./chat-stream.component.scss']
})
export class ChatStreamComponent implements OnChanges, OnInit, OnDestroy {
  appName = appConfig.appName; //To display when user role is admin in chat stream
  @ViewChild('cardBody', { static: true }) cardBody: ElementRef; // To store card body reference
  @ViewChild('AlertContent',  { static: true }) alertContent!: TemplateRef<any>;
  autoScrollInterval: any; // To store auto scroll interval reference
  autoScrollReadableRate = appConfig.autoScrollReadableRate; // To store auto scroll readable rate
  replyCommentForm: FormGroup; // To represent reply comment form
  tabScrollPosition = { // To store tab scroll position
    all: 0, discuss: 0, questions: 0, suggestions: 0, recommends: 0
  };
  @Input() videoId; // VideoId
  @Input() pathwayId;
  @Input() isPublic;
  @Input() disableCommenting;
  getPathwaySubscription: Subscription;
  iconPath = appConfig.iconPath;
  scrollPositionToLoadData = appConfig.scrollPostion; // scroll position to call api
  // tab wise pagination configuration
  tabsPaginationConfig: any;
  comments: any = {
    all: [] = [],// To store all comments
    discuss: [] = [], // type wise comment store in array
    questions: [] = [],
    suggestions: [] = [],
    recommends: [] = []
  };
  commentTabs: any[] = [ // To store comment tabs list
    { name: 'All', container: 'all', type: 'all' },
    // { name: 'Questions', container: 'questions', type: 'QUESTION' },
    // { name: 'Suggestions', container: 'suggestions', type: 'SUGGESTION' },
    // { name: 'Discuss', container: 'discuss', type: 'DISCUSS' },
    // { name: 'Recommendations', container: 'recommends', type: 'RECOMMEND' }
  ];
  currentTab: any = this.commentTabs[0]; // To store current tab detail
  commentType: any;
  isPageScrollDisabled: boolean = true; // To enable disable pagination on scroll

  commentId: string = ""; // To store comment id for reply and flag
  replyCommentId: string = ''; // To store reply comment id
  vdoType: any;
  chatStream: Array<any>[];
  AddCommentSubscription: Subscription; // add comment subscription
  @ViewChild('replyCommentModal', { static: true }) replyCommentModal: TemplateRef<any>;
  replyCommentModalRef: NgbModalRef;
  currentUser: any;
  videoTimeSlot = 0;
  loadChatStreamSubscription: Subscription;//load chat stream subscription
  clickedonHotspot: boolean = false;
  visibility: string = ""; // To check id public page or not
  currentCommentId: string = "";
  alterMessage: string = "";
  
  constructor(
    private dashboardService: DashboardService,
    private commonService: CommonService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) {
    this.currentUser = this.authService.currentUserValue;
  }

  /**
   * Called when input bound properties get changed
   */
  ngOnChanges() {
    // if videoId available then get comments by videoId
    if (this.videoId && this.pathwayId) {
      this.currentTab = this.commentTabs[0]; // reset current tab
      // reset all the comments container
      for (let key in this.comments) {
        this.comments[key] = [];
      }
      this.videoTimeSlot = 0;

      this.resetTabConfiguration();
    }
  }

  initalizeTabConfiguration() {
    this.tabScrollPosition = { // To store tab scroll position
      all: 0, discuss: 0, questions: 0, suggestions: 0, recommends: 0
    };
    // tab wise pagination configuration reset
    this.tabsPaginationConfig = {
      all: {
        page: 1,
        limit: 10,
        total: 0,
        timeSlot: 0,
        totalPages: 0
      },
      questions: {
        page: 1,
        limit: 10,
        total: 0,
        timeSlot: 0,
        totalPages: 0
      },
      discuss: {
        page: 1,
        limit: 10,
        total: 0,
        timeSlot: 0,
        totalPages: 0
      },
      suggestions: {
        page: 1,
        limit: 10,
        total: 0,
        timeSlot: 0,
        totalPages: 0
      },
      recommends: {
        page: 1,
        limit: 10,
        total: 0,
        timeSlot: 0,
        totalPages: 0
      }
    };
  }

  /** reset page comments */
  resetTabConfiguration() {
    this.initalizeTabConfiguration();
    this.getCommentsListByVideo(this.currentTab);
  }

  /**
   * Called when component is being called
   */
  ngOnInit() {
    this.initalizeTabConfiguration();
    this.addCommentBySubscription();
    this.autoScrollInterval = setInterval(() => { this.autoChatStreamScroll() }, 100);
    this.loadChatStreamTimeSlot();
    if (this.activatedRoute.parent.snapshot.routeConfig.path == 'video/:id' || this.isPublic) {
      this.visibility = 'public';
      this.getPathwaySubscription = this.commonService.onGetPathway.subscribe((pathwayId) => {
        if (pathwayId) {
          this.pathwayId = pathwayId;
          let data = {name: 'All', container: 'all', type: 'all'};
          this.onCommentTabChange(data);
        }
      });
    }
  }

  /**
   * Load chat stream comments as per timeslot
   */
  loadChatStreamTimeSlot() {
    this.loadChatStreamSubscription = this.commonService.chatStreamDataFromVideo.subscribe((data) => {
      this.videoTimeSlot = data.timeSlot;
      this.clickedonHotspot = data.hotspot;
      this.resetTabConfiguration();
      const videoData = {
        videoId: this.videoId,
        type: this.currentTab.type,
        page: this.tabsPaginationConfig[this.currentTab.container].page,
        limit: this.tabsPaginationConfig[this.currentTab.container].limit,
        isFlagged: false,
        pathwayId: this.pathwayId,
        timeSlot: this.videoTimeSlot
      };    
      this.dashboardPaginatedCommentList(videoData, this.currentTab, true);
    });
  }

  /**
   * Auto chat stream scroll
   */
  autoChatStreamScroll() {
    // Move scrollbar towards bottom
    this.cardBody.nativeElement.scrollBy(0, this.autoScrollReadableRate);
  }

  /**
   * Called when mouse enter on chat stream body
   */
  onMouseEnterOnChatStreamBody() {
    this.stopAutoScrollChatStream();
  }

  /**
   * Called when mouse leave from chat stream body
   */
  onMouseLeaveOnChatStreamBody() {
    this.startAutoScrollChatStream();
  }

  /**
   * Start auto scroll chat stream
   */
  startAutoScrollChatStream() {
    // Set interval again
    this.autoScrollInterval = setInterval(() => { this.autoChatStreamScroll() }, 100);
  }

  /**
   * Stop auto scroll chat stream
   */
  stopAutoScrollChatStream() {
    // If auto scroll is being performed then stop it
    if (this.autoScrollInterval) {
      clearInterval(this.autoScrollInterval);
    }
  }

  onScroll() {
    let scrollTop = (<HTMLElement>this.cardBody.nativeElement).scrollTop;
    let scrollHeight = (<HTMLElement>this.cardBody.nativeElement).scrollHeight;
    let offsetHeight = (<HTMLElement>this.cardBody.nativeElement).offsetHeight;
    if ((scrollTop + offsetHeight) >= scrollHeight - this.scrollPositionToLoadData && !this.isPageScrollDisabled) {
      //Set next page number
      this.tabsPaginationConfig[this.currentTab.container].page += 1;
      //Check if total page is greater then equal to current page number
      //then get new chat messages by that page number      
      if (this.tabsPaginationConfig[this.currentTab.container].totalPages >= this.tabsPaginationConfig[this.currentTab.container].page) {
        this.isPageScrollDisabled = true;
        this.getCommentsListByVideo(this.currentTab);
      }
    }

    // Reset scrollbar position to top when it reaches bottom
    if ((scrollTop + offsetHeight) >= scrollHeight && !this.isPageScrollDisabled) {
      this.cardBody.nativeElement.scrollTop = 0;
    }
  }

  /**
   * Add comment by subscription
   */
  addCommentBySubscription() {
    this.AddCommentSubscription = this.commonService.addComment.subscribe((comment: any) => {
      // Check if text available then save it
      if (comment.text) {
        this.onSubmitComment(comment);
      }
    });
  }

  /**
   * load all comments in chat stream according to timeslot
   */
  loadAllComments() {
    const videoData = {
      videoId: this.videoId,
      type: this.currentTab.type,
      page: 1,
      limit: this.tabsPaginationConfig[this.currentTab.container].limit,
      isFlagged: false,
      pathwayId: this.pathwayId,
      timeSlot: 0
    };
    this.videoTimeSlot = 0;
    this.dashboardPaginatedCommentList(videoData, this.currentTab, true);
  }
  /**
   * Get comments list by video
   * @param videoId videoId for which video comments
   */
  getCommentsListByVideo(tab) {
    // Set scroll position of current tab
    this.isPageScrollDisabled = true;
    const videoData = {
      videoId: this.videoId,
      type: tab.type,
      page: this.tabsPaginationConfig[tab.container].page,
      limit: this.tabsPaginationConfig[tab.container].limit,
      isFlagged: false,
      pathwayId: this.pathwayId,
      timeSlot: this.videoTimeSlot
    };
    this.dashboardPaginatedCommentList(videoData, tab, false);
  }


  /**
   * comment tab change gesture on select
   * @param tab tab to be selected
   */
  onCommentTabChange(tab) {
    // Stop auto scroll
    this.stopAutoScrollChatStream();
    // Bind current scroll position of current tab
    this.tabScrollPosition[this.currentTab.container] = this.cardBody.nativeElement.scrollTop;

    // Select tab
    this.currentTab = { ...tab };
    if (this.comments[tab.container].length == 0) {
      this.getCommentsListByVideo(tab);
    } else if (this.videoTimeSlot == 0) {
      this.loadAllComments();
    }


    setTimeout(() => {
      // Set scroll position of selected tab
      this.cardBody.nativeElement.scrollTop = this.tabScrollPosition[tab.container];
      // Start auto scroll
      this.startAutoScrollChatStream();
    }, 100);
  }

  /**     
   * call dashboard comment list api 
   */
  dashboardPaginatedCommentList(videoData, tab, flag) {
    this.dashboardService.getVideoCommentsList(videoData, tab.type, 'public').subscribe((data: any) => {
      this.tabsPaginationConfig[tab.container].total = data.totalCount;

      if (data.totalCount < this.tabsPaginationConfig[tab.container].limit) {
        this.tabsPaginationConfig[tab.container].totalPages = 1;
      } else {
        let totalPage: number = data.totalCount / this.tabsPaginationConfig[tab.container].limit;
        this.tabsPaginationConfig[tab.container].totalPages = (Math.floor(totalPage));
        let pageReminder = data.totalCount % this.tabsPaginationConfig[tab.container].limit;
        this.tabsPaginationConfig[tab.container].totalPages += pageReminder == 0 ? 0 : 1;
      }

      if (flag || this.clickedonHotspot) {
        this.comments[tab.container] = data.data;     
      } else {
        this.comments[tab.container] = [...this.comments[tab.container], ...data.data];
      }
      this.isPageScrollDisabled = false;
    });
  }

  /**
   * Open reply modal
   * @param commentId commentId to be replied
   */
  openReplyModal(commentId: string, type: string) {
    this.commentId = commentId;
    setTimeout(() => {
      this.stopAutoScrollChatStream();
    });
    this.replyCommentModalRef = this.modalService.open(this.replyCommentModal, { centered: true });
    this.replyCommentModalRef.result.then(() => {
      this.startAutoScrollChatStream();
    }, () => {
      this.startAutoScrollChatStream();
    });
  }

  /**
   * Open flag comment modal
   * @param commentId commentId for which comment need to be flagged
   * @param tabContainer
   */
  openFlagCommentModal(commentId, replyCommentId = '') {
    this.commentId = commentId;
    this.replyCommentId = replyCommentId;
    setTimeout(() => {
      this.stopAutoScrollChatStream();
    });
    const flagConfirmModalRef = this.modalService.open(FlagConfirmationModalComponent, { centered: true });
    flagConfirmModalRef.result.then((result) => {
      this.startAutoScrollChatStream();
      this.flagComment();
    }, () => {
      this.startAutoScrollChatStream();
    });
  }

  /**
   * submit user comment
   * @param comment comment to be saved
   */
  onSubmitComment(comment) {
    if (this.currentUser || this.visibility !== 'public') {

      //analytic tag
      
      let user=JSON.parse(localStorage.getItem('currentUser'));
      if(user){
        gtag('event',  'video_comment_type', {
          'event_category': user._id,
          'event_label': comment.type
        })

        gtag('event',  this.pathwayId, {
          'event_category': user._id,
          'event_label': comment.text
        })
      };

      

      // set object to pass comment data
      let commentData = {
        text: comment.text,
        type: comment.type,
        videoId: this.videoId,
        pathwayId: this.pathwayId,
        timeSlot: comment.timeSlot
      };

      //call service to add comment in db
      this.dashboardService.addUserComment(commentData).subscribe((res: any) => {
        const commentType = res.data.type;
        // If current tab is all, user adds comment then also that comment should be added in all tab and its relevant tab
        // So get that relevant container
        // Same if current tab is other than all, then comment should be added in that other tab and all tab.
        const relevantContainer = this.getRelevantTabContainerKeyByCommentType(commentType);
        let newComment = res.data;
        // Bind necessary detail which is not came from server
        newComment.user = this.authService.currentUserValue;
        newComment.isDisliked = false;
        newComment.isLiked = false;
        newComment.replyCount = 0;
        // Add newly added comment at top for "all" and relevant tab in chat steam.
        this.comments["all"].unshift(newComment);
        if (relevantContainer != "all" && this.comments[relevantContainer].length) {
          this.comments[relevantContainer].unshift(newComment);
        }
      });
    } else {
      this.router.navigate(['/login']);
    }
  }

  /**
   * Get relevant tab container key by comment type
   * @param commentType commentType to be checked
   */
  getRelevantTabContainerKeyByCommentType(commentType: string) {
    let relevantContainer = "";
    if (commentType == "DISCUSS") {
      relevantContainer = "discuss";
    }
    else if (commentType == "QUESTION") {
      relevantContainer = "questions";
    }
    else if (commentType == "SUGGESTION") {
      relevantContainer = "suggestions";
    }
    else if (commentType == "RECOMMEND") {
      relevantContainer = "recommends";
    }
    else {
      relevantContainer = "all";
    }
    return relevantContainer;
  }

  /**
   * Called when reply comment form gets submitted
   * @param replyComment replyComment to be replied
   */
  submitReplyComment(replyComment) {
    if (this.currentUser || this.visibility !== 'public') {

      // set object to pass comment data
      let commentData = {
        text: replyComment.text,
        type: replyComment.type,
        commentId: this.commentId,
        timeSlot: replyComment.timeSlot
      };
      //call service to reply comment to current comment in db
      this.dashboardService.replyChatComment(commentData).subscribe((res: any) => {

        // Get relevant tab container
        const relevantcontainer = this.getRelevantTabContainerKeyByCommentType(commentData.type);
        const replyCommentsToBeAddedInTabs = ["all", relevantcontainer];
        // Bind necessary detail
        let newReplyComment = {
          dislikes: 0,
          flag: { status: false },
          isDisliked: false,
          isLiked: false,
          likes: 0,
          text: commentData.text,
          type: commentData.type,
          userId: this.authService.currentUserValue,
          _id: res.replyChatId
        };

        // Append reply comment in all and relevant tab
        for (let key of replyCommentsToBeAddedInTabs) {
          let commentIndex = this.comments[key].findIndex((comment) => { return comment._id == this.commentId });
          if (commentIndex != -1) {
            this.comments[key][commentIndex].reply.push(newReplyComment);
            this.comments[key][commentIndex].replyCount += 1; // Update reply count
          }
        }
        this.commentId = "";
        this.replyCommentModalRef.close('');
      });
    } else {
      this.replyCommentModalRef.close('');
      this.router.navigate(['/login']);
    }
  }

  /**
   * Flag comment
   */
  flagComment() {
    if (this.currentUser || this.visibility !== 'public') {

      let comment = {
        "_id": this.commentId,
        "level": 0,
        "isFlagged": true
      };

      // If reply comment flag then pass reply comment info
      if (this.replyCommentId) {
        comment["replyId"] = this.replyCommentId;
        comment.level = 1;
      }

      this.dashboardService.flagComment(comment).subscribe(() => {
        // Remove comment from the list
        for (let key in this.comments) {
          if (key == "all" || this.currentTab.container == "all" || key == this.currentTab.container) {
            if (!this.replyCommentId) {
              let index = this.comments[key].findIndex((com) => { return com._id == this.commentId });
              if (index != -1) {
                this.comments[key].splice(index, 1);
              }
            }
            else {
              for (let comment of this.comments[key]) {
                let replyFlagCommentIndex = comment.reply.findIndex((com) => { return com._id == this.replyCommentId });
                if (replyFlagCommentIndex != -1) {
                  comment.reply.splice(replyFlagCommentIndex, 1);
                }
              }
            }
          }
        }
        this.commentId = this.replyCommentId = '';
      }, (error) => {
      });
    } else {
      this.router.navigate(['/login']);
    }
  }

  /**
   * chat stream comment like dislike
   * @param data like dislike particular comment
   */
  likeDislikeComment(isUserLike: boolean, type: string, commentDetail: any, level: number, reply?: any) {
    if (this.currentUser || this.visibility !== 'public') {
      const relevantContainer = this.getRelevantTabContainerKeyByCommentType(commentDetail.type);
      let statusToChange = isUserLike ? null : true; // status to be change
      let data: any = {
        level: level,
        commentId: commentDetail._id
      };

      if (type == 'like') {
        data.isLiked = statusToChange;
      } else {
        data.isDisliked = statusToChange;
      };

      //if level 1 exists
      level == 1 ? data.replyId = reply._id : '';

      /** like dislike comment in chatstream service */
      this.dashboardService.likeDisLikeComment(data).subscribe(() => {
        if (this.comments[relevantContainer].length > 0) {
          // it will update comment and reply - like dislike action in relevant tab
          if (this.currentTab.container != "all") {
            let getAllCommentIndex = this.comments['all'].findIndex(item => item._id == commentDetail._id);
            getAllCommentIndex > -1 ? this.comments['all'][getAllCommentIndex] = commentDetail : '';
          } else {
            let getCommentIndex = this.comments[relevantContainer].findIndex(item => item._id == commentDetail._id);
            getCommentIndex > -1 ? this.comments[relevantContainer][getCommentIndex] = commentDetail : '';
          }
        }

        if (type == 'like') {
          if (level == 1) {
            reply.isLiked = data.isLiked;
            // Update likes if user liked it and remove likes if user unliked it
            reply.likes += data.isLiked ? 1 : -1;
            // If user liked from dislike then remove dislikes
            if (reply.isDisliked) {
              reply.isDisliked = false;
              reply.dislikes -= 1;
            }
          } else {
            commentDetail.isLiked = data.isLiked;
            // Update likes if user liked it and remove likes if user unliked it
            commentDetail.likes += data.isLiked ? 1 : -1;
            // If user liked from dislike then remove dislikes
            if (commentDetail.isDisliked) {
              commentDetail.isDisliked = false;
              commentDetail.dislikes -= 1;
            }
          }
        } else {
          if (level == 1) {
            reply.isDisliked = data.isDisliked;
            // Update reply comment dislikes if user disliked it and remove likes if user liked it
            reply.dislikes += data.isDisliked ? 1 : -1;
            // If user reply comment disliked from like then remove likes
            if (reply.isLiked) {
              reply.isLiked = false;
              reply.likes -= 1;
            }
          } else {
            commentDetail.isDisliked = data.isDisliked;
            // Update comment dislikes if user disliked it and remove dislikes if user liked it
            commentDetail.dislikes += data.isDisliked ? 1 : -1;
            // If user comment disliked from like then remove likes
            if (commentDetail.isLiked) {
              commentDetail.isLiked = false;
              commentDetail.likes -= 1;
            }
          }
        }
      });
    } else {
      this.router.navigate(['/login']);
    }
  }

  openShowProfileUserModal(userId: any) {
    if (this.currentUser || this.visibility !== 'public') {
      if (userId != this.currentUser._id) {
        // retrive user detail
        this.dashboardService.getUserDetail(userId).subscribe((res: any) => {
          /**
           * open user profile modal to see profile and follow user
           */
          const profileModalRef = this.modalService.open(ShowProfileModalComponent, { centered: true, windowClass: 'modal-small' });
          profileModalRef.componentInstance.userDetail = res.data[0];
          profileModalRef.result.then((result) => {
          }, () => {
          });
        });

      }
    } else {
      this.router.navigate(['/login']);
    }
  }
  
  showPopup(commentId) {
    this.currentCommentId = commentId
    this.alterMessage = "Do you want to delete this comment ?"
    this.modalService.open(this.alertContent, { centered: true, windowClass : 'unlock-premium' })
  }
  
  deleteComment(){
    let data = {
      commentId : this.currentCommentId,
    }
    this.dashboardService.deleteComment(data).subscribe((response : any) => {
      if(response.success) {
        this.comments.all = this.comments.all.filter((comment) => comment._id !== this.currentCommentId)
        this.currentCommentId = ''
      }
    });
    // Swal.fire({
    //   html: "Do you want to delete this comment ?",
    //   icon: 'info',
    //   showCancelButton: true,
    //   confirmButtonText: 'Yes, Delete',
    //   focusConfirm: false,
    //   cancelButtonText: 'No',
    //   reverseButtons: true,
      
    // }).then((result) => {
    //   if (result.isConfirmed) {

    //   }
    // }).catch(er => {
    //   console.log(er)
    // });
  }

  /**
   * Called when component being destoyed
   */
  ngOnDestroy() {
    // Clean subscription if subscribed
    if (this.AddCommentSubscription) {
      this.AddCommentSubscription.unsubscribe();
    }
    this.loadChatStreamSubscription ? this.loadChatStreamSubscription.unsubscribe() : "";
    // Clear autoscroll interval
    this.stopAutoScrollChatStream();
  }

}
