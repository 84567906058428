import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { appConfig } from 'src/app/_core/config/app-config.const';
import { environment } from 'src/environments/environment';
import { DashboardService } from '../../services/dashboard.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/_services/common.service';
import { Router } from '@angular/router';
@Component({
  selector: 'tangram-curator-info',
  templateUrl: './curator-info.component.html',
  styleUrls: ['./curator-info.component.scss']
})
export class CuratorInfoComponent implements OnInit {
  @Input() curatorInfo: any;
  @Input() currentUser: any;
  @Input() followedThisUser;
  @Output() showLoginAlert = new EventEmitter();

  iconPath = appConfig.iconPath;
  imageUrl = environment.backendUrl;
  clickDisabled : boolean = false;
  // followedUser : boolean = false;
  constructor(
    private dashboardService:DashboardService,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal,
    private commonService: CommonService,
    private router: Router
  ) { 
  }

  ngOnInit() {
  }

  handleFollowToggle() {
    this.cd.detectChanges();
    setTimeout(() => {
      this.followedThisUser = !this.followedThisUser
    },0)
  }

  changeFollowUnfollow(value, modalContent: TemplateRef<any>){
    if (!this.currentUser) {
      this.showLoginAlert.emit()
      return;
    }
    if(!this.currentUser.subscriptionActive){
      // this.handleFollowToggle();
      this.modalService.open(modalContent, { centered: true, windowClass : 'unlock-premium' })
      return ;
    }
    this.clickDisabled = true
    let data = {
      "_id": this.curatorInfo._id,
      "isFollowed": value
    };
    this.dashboardService.followUnfollowUser(data).subscribe((data : any) => {
      this.clickDisabled = false;
      if(data.success){
        this.followedThisUser = !this.followedThisUser
      }
    })
  }
}
