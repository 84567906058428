import { AfterViewInit, Component, Input, OnDestroy, OnInit, Renderer2, TemplateRef, EventEmitter, Output } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { appConfig } from 'src/app/_core/config/app-config.const';
import { CommonService } from 'src/app/_services/common.service';
import { DashboardService } from '../../services/dashboard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { environment } from 'src/environments/environment';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { ToastrService } from 'ngx-toastr';
import { TinyUrlService } from '../../services/tinyurl.service';
declare let gtag: Function;
declare var $: any;
@Component({
  selector: 'tangram-ratings',
  templateUrl: './ratings.component.html',
  styleUrls: ['./ratings.component.scss']
})
export class RatingsComponent implements OnInit, OnDestroy {
  @Input() ratings: any;
  @Input() isDashboard: boolean;
  @Input() videoId;
  @Input() pathwayId: any;
  @Input() userRating: any;
  @Input() createdById: any;
  @Input() altPathId: any;
  @Output() showLoginAlert = new EventEmitter();

  shareUrl: string = '';
  shareVideoUrl: string = '';
  fbShareUrl: string = '';
  inAppShareUrl:string = '';
  whatsAppShareUrl: string = '';
  whatsAppShareUrl1: string = '';
  isBookmarked: boolean = false;
  iconPath = appConfig.iconPath;
  openTangramFooterSubScription: Subscription;
  currentUser: any;
  visibility: string = ""; // To check id public page or not
  private ngNavigatorShareService: NgNavigatorShareService;
  private iosReg : any;
  // private andoiidReg : any;
  constructor(
    private dashboardService: DashboardService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private modalService: NgbModal,
    ngNavigatorShareService: NgNavigatorShareService,
    private tinyUrlService: TinyUrlService,
    private toasterService: ToastrService,

    ) {
      this.currentUser = this.authService.currentUserValue;
      this.ngNavigatorShareService = ngNavigatorShareService;
    }
    
  async ngOnInit() {
    //call subscription
    this.iosReg = /iphone|ipad/i;
    // this.andoiidReg = /Mobile|Android/i;
    this.activatedRoute.params
      .subscribe(params => {
        this.shareUrl = environment.appRedirectUrl + '/#/dashboard/' + params.pathwayId
      }
    );
    this.shareUrl = await this.shortenUrlFunc(this.shareUrl)
    // this.shareUrl = window.location.href;
    // this.shareUrl = this.shareUrl.split('/').splice()
    // urlArr.pop();
    


    if (this.activatedRoute.parent.snapshot.routeConfig.path == 'video/:id') {
      this.visibility = 'public';
    }
    this.shareVideoUrl = window.location.href.split('?')[0] + '?videoId=';
    this.fbShareUrl = environment.fbShareUrl;
    this.inAppShareUrl = environment.inAppShareUrl
    let details = navigator.userAgent;

    let regexp = /android|iphone|kindle|ipad/i;

    /* Using test() method to search regexp in details
    it returns boolean value*/
    let isMobileDevice = regexp.test(details);

    if (isMobileDevice) {
      this.whatsAppShareUrl=environment.whatsAppShareUrlMobile;
    } else {
      this.whatsAppShareUrl=environment.whatsAppShareUrl;
    }
    
    this.openTangramFooterSubScription = this.commonService.openTangramFooterMenu.subscribe((param: any) => {
      this.toggleTangramButtonModal();
    });
  }

  copyLink(type){
    // const content = document.getElementById('share-tangram-url').textContent;
    // loading the content into our clipboard
    let link = this.shareUrl;
    if (type == 'video') {
      link = this.shareVideoUrl;
    }
    navigator.clipboard.writeText(link);
  
  }

  async openSharePopup(type) {
    if(this.videoId){
      this.shareVideoUrl = await this.shortenUrlFunc(window.location.href.split('?')[0] + '?videoId=' + this.videoId)
    }
    // this.shareVideoUrl = environment.appRedirectUrl + '/#/dashboard/' + this.pathwayId + '?videoId=';
    let link = type !== 'video' ? this.shareUrl : (this.shareVideoUrl);
    document.getElementById("share-tangram-url").setAttribute('value', link);
    let isIOS = this.iosReg.test(navigator.userAgent);
    var popup = document.getElementById("share-popup");
    var btnPopup = document.getElementById("shareBtn");
    if(isIOS){
      this.ngNavigatorShareService.share({
        title: '',
        // text: 'Sharing My tangram',
        url: link
      }).then( (response) => {
        console.log(response);
      })
      .catch( (error) => {
        console.log(error);
      })    
    }
    else{
      if(popup) {
        popup.classList.toggle("show");
      }
      if(btnPopup) {
        btnPopup.classList.toggle("visible");
      }
    }

  }

  /**
 * redirects user to share popup
 * @param link to be shared
 */
  shareRedirect(appUrl,link) {
    var shareURL = appUrl ? appUrl + encodeURIComponent(link) : link;
    window.open(shareURL,'popup','width=600,height=600'); 
    return false;
  }

  /**
   * Called once, before the instance is destroyed.
   */
  ngOnDestroy(): void {
    this.openTangramFooterSubScription ? this.openTangramFooterSubScription.unsubscribe() : '';
  }

  /**
   * Toggle tangram button modal
   */
  toggleTangramButtonModal() {
    $(".tangram-buttons").toggle();
  }

  /**
   * open spotlight modal in mobile
   */
  openSpotLightModal() {
    this.commonService.openSpotLightModalFooter(true);
  }

  /**
 * submit user comment
 * @param comment comment to be saved
 */
  onSubmitComment(comment) {
    // set object to pass comment data
    let commentData = {
      text: comment.text,
      type: comment.type,
      timeSlot: comment.timeSlot
    };

    this.commonService.raiseEventToAddComment(commentData);
    $(".tangram-buttons").toggle();
  }

  /**
   * Do like dislike and favourite video
   * @param currentStatus currentStatus to check current status
   * @param selectedType selectedType to check like, dislike or favorite
   */
  likeDislikeVideo(currentStatus, selectedType) {
    if (this.currentUser || this.visibility !== 'public') {
      let data: any = { videoId: this.videoId };
      let statusToChange = currentStatus ? null : true; // status to be changed
      // Bind update status based on current based on selected type to change
      if (selectedType == 'like') {
        alert('Like')
        data.isLiked = statusToChange;
      }
      else if (selectedType == 'dislike') {
        data.isDisliked = statusToChange;
      }
      else {
        data.isFavourite = statusToChange;
      }
      data.pathwayId = this.pathwayId;

      this.dashboardService.likeDislikeVideo(data).subscribe((response: any) => {
        // Update count and active status based on current status
        if (data.isFavourite) {
          gtag('event', 'like_tangram', {
            'video_id': data.videoId,
          });
        }
        if (selectedType == 'like') {
          this.userRating.isLiked = data.isLiked;
          // Update likes if user liked it and remove likes if user unliked it
          this.ratings.likes += data.isLiked ? 1 : -1;
          // If user liked from dislike then remove dislikes
          if (this.userRating.isDisliked) {
            this.userRating.isDisliked = false;
            this.ratings.disLikes -= 1;
          }
        }
        else if (selectedType == 'dislike') {
          this.userRating.isDisliked = data.isDisliked;
          // Update dislikes if user disliked it and remove dislikes if user liked it
          this.ratings.disLikes += data.isDisliked ? 1 : -1;
          // If user disliked from like then remove likes
          if (this.userRating.isLiked) {
            this.userRating.isLiked = false;
            this.ratings.likes -= 1;
          }
        }
        else {
          this.userRating.isFavourite = data.isFavourite;
          this.ratings.favourites += data.isFavourite ? 1 : -1;
        }
      });
    } else {
      this.router.navigate(['/login']);
    }
  }

  bookmarkTangram(flag,modalContent: TemplateRef<any>) {
    if (!this.currentUser) {
      this.showLoginAlert.emit()
      return;
    }
    if(!this.currentUser.subscriptionActive){
      this.modalService.open(modalContent, { centered: true, windowClass : 'unlock-premium' })
      return ;
    }
    this.ratings.isBookmarked = flag;
    let data = {
      isBookmarked: flag,
      userId: this.currentUser._id,
      pathwayId: this.altPathId
    }
    this.dashboardService.bookmarkPathway(data).subscribe((response: any) => {
    });
  }

  /**
   * Open share modal
   * @param content content to be displayed as modal
   */
  openShareModal(content) {
    this.modalService.open(content, { centered: true }).result.then(() => {
    }, () => {
    });
  }

  async shortenUrlFunc(longUrl) {
    try {
      const response: any = await this.tinyUrlService.shortenUrl(longUrl).toPromise();
      return response.data.tiny_url; // Return the shortened URL
    } catch (error) {
      console.error('Error occurred while shortening URL:', error);
      this.toasterService.error(error)
      return longUrl
    }
  }
  
}
