import { ChangeDetectorRef, Renderer2, TemplateRef } from '@angular/core';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { appConfig } from 'src/app/_core/config/app-config.const';
import { CommonService } from 'src/app/_services/common.service';
import { DashboardService } from './services/dashboard.service';
import { environment } from '../../../environments/environment';
import { ActivatedRoute,Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

declare var $: any;
@Component({
  selector: 'tangram-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChild('UnlockPremiumModalContent',  { static: true }) unlockPremiumModalContent!: TemplateRef<any>;
  @ViewChild('AlertContent',  { static: true }) alertContent!: TemplateRef<any>;
  isDashboard: boolean = true;
  isMobile: boolean = false;
  iconPath = appConfig.iconPath;
  videoData:null;
  /** pathway detail */
  public pathwayDetail: any = {
    title: '',
    description: ''
  }
  public ratingCounts = {};
  userRatings = {}; // To store user video ratings
  public videosDuration = { totalVideo: '', totalVideoMinutes: '' }; // To store videos duration details
  /** pass curated info to the component */
  public curatorInfo: any = {
    _id: null,
    firstName: '',
    lastName: ''
  };
  /** pass content processed data to the component */
  public contentProcessed: any = {
    data: 0
  };
  public currentVideoDetail: any = { _id: '' }; // To store current video detail
  public trackVideoDetail = {}; // To store track video detail
  upskills: any[]; // To store upskills

  playlists: any[]; // To store playlist
  thumbnailsList : any[];
  spotlights: any[]; // To store spotlights
  videoId: string = ""; // To store video id
  // upskill scroll event for mobile
  @ViewChild('upskillWeb', { static: false }) upskillWeb: ElementRef;
  showDashboardSubScription: Subscription;
  scrollToUpSkillSubScription: Subscription;
  openSpotLightModalSubScription: Subscription;
  changeViewSubscription: Subscription;
  showDashboardOverviewView: boolean = true; // To show hide dashboard overview view
  showForumView: boolean = false; // To show hide forum view
  showForumQuestionView: boolean = false; //To show hide forum question view
  tag: any;
  pathwayId: string = ""; // To store pathway id
  createdBy: string = ""; // To store createdBy id
  overviewImageUrl: string = ""; // To store image url
  videoURL: any = "";
  showFeedbackModal: boolean;
  visibility: string = ""; // To check id public page or not
  publicVisibility: boolean = false;
  currentUser: any;
  isPathwayListRoute: boolean = false;
  currentPathwayId: any = '';
  isCurrentScreenVideo: boolean = false;
  isvideoAvailable: boolean = false;
  platForm:String = '';
  isCommentDisable : boolean = false;
  followedUsers : any[] = [];
  followedThisUser : boolean = false;
  numberOfCurators : number = 0;
  alterMessage : String =  ''
  constructor(
    private cd: ChangeDetectorRef,
    private dashboardService: DashboardService,
    private renderer: Renderer2,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private commonService: CommonService,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    ) {
      this.currentUser = this.authService.currentUserValue;
    }
    
    ngOnInit() {
    this.activatedRoute.queryParams.subscribe((param) => {
      if (param.videoId) {
        this.videoId = param.videoId;
        this.isvideoAvailable = true;
        if (!this.currentUser) {
          this.visibility = 'public';
          this.publicVisibility = true;
          if (this.isvideoAvailable) {
            this.isDashboard = false;
            this.getVideoDetail(this.videoId,'manual', 'public');
          }
        }
      }
    });
    
    this.activatedRoute.params.subscribe((param) => {
      if (param && param.pathwayId) {
        this.isPathwayListRoute = true;
        this.currentPathwayId = param.pathwayId;
      }
      if (this.activatedRoute.parent.snapshot.routeConfig.path == 'video/:id') {
        this.visibility = 'public';
        this.publicVisibility = true;
      }
      this.getOverviewDetail();
      // Set isFromAM if available
      if (param.id) {
        this.isDashboard = false;
        // this.currentVideoDetail = param.video;
        this.videoId = param.id;
        this.getVideoDetail(param.id,'manual', 'public');
        // console.log("videoData",this.videoData)
        // this.pathwayDetail = { title: video.title, description: video.description, tagName: video.tag.name };
        // this.isFromAccountManagement = param.isFromAM == "true";
      }
    });

    //call subscription
    this.showDashboardSubScription = this.commonService.showDashboard.subscribe((param: any) => {
      param ? this.onDashboardClick() : '';
    });
    this.scrollToUpSkillSubScription = this.commonService.scrollToUpSkill.subscribe(() => {
      // scroll to particular div-upskill
      this.upskillWeb.nativeElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    });
    this.openSpotLightModalSubScription = this.commonService.openSpotLightModal.subscribe((param: boolean) => {
      param ? this.openSpotLightModal() : '';
    });
    this.handleChangeViewSubscription();
    this.getCuratorsCount()
  }

  /**
  * Called once, before the instance is destroyed.
  */
  ngOnDestroy(): void {
    this.showDashboardSubScription ? this.showDashboardSubScription.unsubscribe() : '';
    this.scrollToUpSkillSubScription ? this.scrollToUpSkillSubScription.unsubscribe() : '';
    this.openSpotLightModalSubScription ? this.openSpotLightModalSubScription.unsubscribe() : '';
    this.renderer.removeClass(document.body, 'video-detail-page');
  }

  /**
   * Get overview detail
   */
  getOverviewDetail() {
    this.isCurrentScreenVideo = false;
    this.isPathwayListRoute = true;
    if(this.currentUser || this.visibility !== 'public') {
      if (this.isDashboard) {
        this.renderer.removeClass(document.body, 'video-detail-page');
      }
      this.callFooterCommonEvent();
      let pathwayId = this.currentPathwayId;
      if(this.currentUser){
        this.dashboardService.getMyFollowingUsers().subscribe((users) => {
          this.followedUsers = users.data
        })
      }
      
      this.dashboardService.getPathwayDetail(pathwayId).subscribe((data: any) => {
        // Bind curator information
        this.curatorInfo = data.data.admin;
        this.followedThisUser = this.followedUsers.some((user) => user._id === this.curatorInfo._id)
        // console.log('this.followedThisUser: ', this.followedThisUser);
        // Extract necessary details from response
        const { _id, title, description, imageURL, tempImageURL, ratingCounts, totalVideos, totalDurationMIN, videos, upskills, totalVideoWatchPercentage, createdBy } = data.data;
        this.pathwayId = _id;
        this.createdBy = createdBy;
        // Bind pathway id in localstorage
        localStorage.setItem("pathwayId", this.pathwayId);
        if (imageURL) {
          this.overviewImageUrl = `${environment.backendUrl}${imageURL}`;
        } else if (tempImageURL) {
          this.overviewImageUrl = tempImageURL;
        }
        else {
          this.overviewImageUrl = "assets/img/videos/img6.png";
        }

        this.commonService.raiseEventToGetPathwayInHeaderAndFooter(this.pathwayId);
        // Bind total video watch percentage for overview
        this.contentProcessed.data = totalVideoWatchPercentage;
        // Bind detail like title and description
        this.pathwayDetail = { title, description };
        // Bind different counts like users, likes, comments, questions
        this.ratingCounts = ratingCounts;
        this.videosDuration = { totalVideo: totalVideos, totalVideoMinutes: totalDurationMIN };
        // if (this.playlists == undefined) {
        // Bind videos
        this.playlists = videos;
        this.thumbnailsList = [...this.playlists]
        // }
        // Bind upskills
        this.upskills = upskills;
        if (this.isvideoAvailable) {
          this.isDashboard = false;
          this.getVideoDetail(this.videoId,'manual');
        }
      }, error => {
        if (error.status == 400 && error.error.message == "Please select topic") {
          this.router.navigate(['/configuration/preferences/5']);
        }

      });
    }
  }

  /**
   * Get video detail
   */
  getVideoDetail(videoId,from='', visibility='') {
    this.isCurrentScreenVideo = true;
    this.isPathwayListRoute = false;
    if (!this.isDashboard) {
      this.renderer.addClass(document.body, 'video-detail-page');
    }
    this.callFooterCommonEvent();
    if (visibility == 'public') {
      this.dashboardService.getVideoDetailPublic(videoId).subscribe((data: any) => {
        const { spotlights, ratingCounts, isLiked, tag, isDisliked, isFavourite, pathwayId, platForm, videoURL } = data.data;
        this.currentPathwayId = pathwayId;
        if(from=='manual'){
          this.currentVideoDetail = data.data;
          this.pathwayDetail = { title: data.data.title, description: data.data.description, tagName: data.data.tag.name };
        }
        this.pathwayId = data.data.pathwayId;
        this.commonService.raiseEventToGetPathwayInHeaderAndFooter(data.data.pathwayId);
        this.ratingCounts = ratingCounts;
        this.userRatings = { isLiked, isDisliked, isFavourite };
        this.spotlights = spotlights;
        // video dashboard
        this.tag = tag;
        this.videoURL = this.getSafeUrl(videoURL)
        if(platForm == 'Other'){
          if(videoURL.includes('spreadsheets') || videoURL.includes('document') || videoURL.includes('drive')){
            const urlParts = videoURL.split('/');
            urlParts[urlParts.length - 1] = 'preview';
            let iframeURL = urlParts.join('/');
            console.log('iframeURL: ', iframeURL);
            this.videoURL = this.getSafeUrl(iframeURL)
          }else{
            let notAddProxy = ['wikipedia', 'axios', 'cbsnews'];
            let enCodeURL = encodeURIComponent(videoURL)        
            let newProxyURL = environment.proxyWorkerURL + '?id=1&interceptorNotNeeded=true&apiURL=' + enCodeURL;
            if(!environment.production){
              newProxyURL = environment.proxyWorkerURL + '?id=2&interceptorNotNeeded=true&apiURL=' + enCodeURL;
            }
            let notNeedProxy = notAddProxy.some((value) => videoURL.includes(value))
            if(notNeedProxy){
              this.videoURL = this.getSafeUrl(videoURL)
            }else{
              this.videoURL = this.getSafeUrl(newProxyURL)
            }
          }
        }
        this.platForm = platForm

        
        // Make chat stream scrollable
        setTimeout(() => {
          if ($(window).width() >= 992) {
            $('.discussion').css({
              'height': $('.video-section').innerHeight()
            });
          }
        }, 500);
      });
    } else {
      this.dashboardService.getVideoDetail(videoId).subscribe((data: any) => {
        const { spotlights, ratingCounts, isLiked, tag, isDisliked, isFavourite, platForm, videoURL, disableComment } = data.data;
        this.isCommentDisable = disableComment
        if(from=='manual'){
          this.currentVideoDetail = data.data;
          this.pathwayDetail = { title: data.data.title, description: data.data.description, tagName: data.data.tag ? data.data.tag.name : '' };
        }
      
        // Change the platFrom according to the entered link        
        this.videoURL = this.getSafeUrl(videoURL)
        if(platForm == 'Other'){
          if(videoURL.includes('spreadsheets') || videoURL.includes('document') || videoURL.includes('drive')){
            const urlParts = videoURL.split('/');
            urlParts[urlParts.length - 1] = 'preview';
            let iframeURL = urlParts.join('/');
            console.log('iframeURL: ', iframeURL);
            this.videoURL = this.getSafeUrl(iframeURL)
          }else{
            let notAddProxy = ['wikipedia', 'axios', 'cbsnews'];
            let enCodeURL = encodeURIComponent(videoURL)        
            let newProxyURL = environment.proxyWorkerURL + '?id=1&interceptorNotNeeded=true&apiURL=' + enCodeURL;
            if(!environment.production){
              newProxyURL = environment.proxyWorkerURL + '?id=2&interceptorNotNeeded=true&apiURL=' + enCodeURL;
            }
            let notNeedProxy = notAddProxy.some((value) => videoURL.includes(value))
            if(notNeedProxy){
              this.videoURL = this.getSafeUrl(videoURL)
            }else{
              this.videoURL = this.getSafeUrl(newProxyURL)
            }
          }
        }
        // console.log('this.videoURL: ', this.videoURL);

        this.platForm = platForm
        this.ratingCounts = ratingCounts;
        this.userRatings = { isLiked, isDisliked, isFavourite };
        this.spotlights = spotlights;
        // video dashboard
        this.tag = tag;
        // Make chat stream scrollable
        setTimeout(() => {
          if ($(window).width() >= 992) {
            $('.discussion').css({
              'height': $('.video-section').innerHeight()
            });
          }
        }, 500);
      });
      this.getTrackedVideoWatchDashboard(videoId);
      this.cd.detectChanges();
      // Update totalViewCount for other platforms
      if(this.platForm !== 'YouTube') {
        let watchDataObject = {
          totalSeconds : 1,
          totalSeenSeconds : 1,
          percentage : 1,
          videoId:videoId 
        }
        this.onUpdateConsumedContent(watchDataObject)
      }
    }
    // this.dashboardService.getTrackedVideoWatch(videoId).subscribe((data: any) => {
    //   this.trackVideoDetail = data.data;
    //   this.contentProcessed.data = data.data.percentage;
    // });
  }

  drop(event: CdkDragDrop<string[]>, modalContent: TemplateRef<any>) {
    if(!this.currentUser.subscriptionActive){
      this.modalService.open(modalContent, { centered: true, windowClass : 'unlock-premium' })
      return ;
    }
    if (event.previousIndex == event.currentIndex) return;
    this.thumbnailsList = [];

    const prevItem = this.playlists[event.previousIndex];
    const currItem = this.playlists[event.currentIndex];
    let payload = {
      from: {
        videoId: currItem._id,
        order: event.previousIndex + 1,
      },
      to: {
        videoId: prevItem._id,
        order: event.currentIndex + 1,
      }
    };
    
    this.dashboardService.updateVideosOrder(payload).subscribe((data) => {
      moveItemInArray(this.playlists, event.previousIndex, event.currentIndex);
      this.thumbnailsList = [...this.playlists]
      // Update thumbnail Image of Tangram
      this.updateOverviewImageUrl(null,this.playlists)
    });
  }

  /**
   * Get video watched status
   * @param videoId videoid watched status
   */
  getTrackedVideoWatchDashboard(videoId) {
    this.dashboardService.getTrackedVideoWatch(videoId).subscribe((data: any) => {
      this.trackVideoDetail = data.data;
      this.contentProcessed.data = data.data.percentage;
    });
  }

  /**
   * Edit Tangram (Pathway)
   * @param pathwayId pathwayId
   */
  editTangram(pathwayId: any) {
    this.router.navigate(['edit-tangram', pathwayId]);
  }

  /**
   * Edit Tangram (Pathway) Video
   * @param pathwayId pathwayId
   */
  editTangramVideo(pathwayId: any) {
    this.router.navigate(['/edit-tangram-video', this.videoId]);
  }

  /**
   * Add Youtube Video
   * @param pathwayId pathwayId
   */
  addYoutubeVideo(pathwayId: any) {
    this.router.navigate(['/create-tangram'], { queryParams : { pathwayId: pathwayId } });
  }

  /**
   * On playlist click
   * @param video video to be played
   */
  onPlaylistClick(video) {
    if (!this.currentUser) {
      this.showLoginAlert()
      return;
    }    
    this.isDashboard = false;
    this.currentVideoDetail = video;
    this.videoId = video._id;
    this.getVideoDetail(video._id);
    this.pathwayDetail = { title: video.title, description: video.description, tagName: video.tag.name, videoURL : video.videoURL };
  }

  /**
   * laod dashboard overview page
   */
  onDashboardClick() {
    this.isDashboard = true;
    this.currentVideoDetail = {};
    this.trackVideoDetail = {};
    this.getOverviewDetail();
  }

  /** call footer event */
  callFooterCommonEvent() {
    this.commonService.raiseDashboardFooterEvent(this.isDashboard);
  }

  /**
  * Show video description modal
  */
  openSpotLightModal() {
    $("#spotlight").appendTo('body').modal('show');
  }

  /**
   * Called when update consumed content
   * @param data data to be tracked
   */
  onUpdateConsumedContent(data) {
    this.dashboardService.trackVideoWatch(data).subscribe((data: any) => {
      // Update consumed percentage
      const { percentage } = data.data;
      this.contentProcessed.data = percentage;
    });
  }

  /**
   * Load next video
   */
  loadNextVideo() {
    // Get current video position from playlist
    let currentVideoIndex = this.playlists.findIndex((playlist) => { return playlist._id == this.videoId });
    // Check video position available and video on next position is available then play next video
    if (currentVideoIndex != -1 && currentVideoIndex < (this.playlists.length - 1)) {
      this.onPlaylistClick(this.playlists[currentVideoIndex + 1]);
    }
  }

  /**
   * Handle change view subscription
   */
  handleChangeViewSubscription() {
    this.changeViewSubscription = this.commonService.onChangeView.subscribe((viewName: string) => {

      switch (viewName) {
        case 'dashboardOverview':
          this.showForumView = false;
          this.showForumQuestionView = false;
          this.showDashboardOverviewView = true;
          break;
        case 'forum':
          this.showForumView = true;
          this.showForumQuestionView = false;
          this.showDashboardOverviewView = false;
          break;
        case 'question':
          this.showForumQuestionView = true;
          this.showForumView = false;
          this.showDashboardOverviewView = false;
          break;
        default:
      }
    });
  }

  callback(entries, observer) {
    entries.forEach(entry => {
      // Each entry describes an intersection change for one observed
      // here you animate another element and do whatever you like
    });
  };

  /**
   * Update overview image url if given url is not valid
   * @param event
   */
  updateOverviewImageUrl(event,playlists) {
    if(playlists.length){
      this.overviewImageUrl = this.playlists[0].thumbnail.mediumURL;
    }else{
      this.overviewImageUrl = "assets/img/videos/img6.png";
    }
  }
  getSafeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  openIframeExpand(modalContent: TemplateRef<any>){
    this.modalService.open(modalContent, { centered: true, windowClass: 'iframe-modal', scrollable: true })
  }

  handleNext() {
    let videoList = this.playlists;
    let nextVideo;
    videoList.forEach((val, index) => { 
      if (val._id == this.currentVideoDetail._id) {
        nextVideo = this.playlists[index + 1];
        if (index + 1 >= this.playlists.length) {
          nextVideo = this.playlists[0];
        }
      }      
    })
    this.currentVideoDetail = nextVideo;
    this.onPlaylistClick(this.currentVideoDetail);
  }

  handlePrev() { 
    let videoList = this.playlists;
    let nextVideo: any;
    videoList.forEach((val, index) => {
      if (val._id == this.currentVideoDetail._id) {
        nextVideo = this.playlists[index - 1];
        if (index - 1 < 0) {
          let length = this.playlists.length;
          nextVideo = this.playlists[length - 1];
        }
      }
    })
    this.currentVideoDetail = nextVideo;
    this.onPlaylistClick(this.currentVideoDetail);
  }

  handleDeleteMedia(mediaId){
    let isLastMedia = false
    if(this.playlists.length == 1){
      isLastMedia = true;
      if(!this.currentUser.subscriptionActive){
        this.modalService.open(this.unlockPremiumModalContent, { centered: true, windowClass : 'unlock-premium' })
        return; 
      }  
    }
    let data = {
      mediaId : mediaId,
      isLastMedia : isLastMedia,
      pathwayId : this.currentPathwayId
    }
    
    this.dashboardService.deleteMedia(data).subscribe((res : any) => {
      if(res.success) {
        let pathwayId = this.currentPathwayId;
        this.dashboardService.getPathwayDetail(pathwayId).subscribe((data: any) => {
          this.thumbnailsList = [];
          const { totalVideos, totalDurationMIN, videos,} = data.data;
          this.playlists = [...videos];
          this.thumbnailsList = [...this.playlists]
          this.videosDuration = { totalVideo: totalVideos, totalVideoMinutes: totalDurationMIN };
          this.updateOverviewImageUrl(null,this.playlists)
          
          if(!this.playlists.length){
            this.router.navigate(['/my-tangram']);
            return;
          }
        })
      }
    })
  }

  changeCommentDisable(event, modalContent: TemplateRef<any>){
    if(!this.currentUser.subscriptionActive){
      this.cd.detectChanges();
      this.modalService.open(modalContent, { centered: true, windowClass : 'unlock-premium' })
      setTimeout(() => {
        this.isCommentDisable = !this.isCommentDisable
      },0)
      return ;
    }
    let data = {
      video_id : this.videoId,
      disableComment : this.isCommentDisable
    }
    this.dashboardService.disableCommenting(data).subscribe((response) => {
    })
  }

  getCuratorsCount() {
    this.authService.curatorsCount().subscribe((res: any) => {
      this.numberOfCurators = res.count;
    })
  }

  showLoginAlert() {
    this.alterMessage = `Login required — sign-up for free to join ${this.numberOfCurators} users on Tangramio`
    this.modalService.open(this.alertContent, { centered: true, windowClass : 'unlock-premium' })
  }

  navigateToLogin(){
    this.router.navigate(['/login'],  { queryParams: { returnUrl: this.router.url }});
  }
}
