// tinyurl.service.ts

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TinyUrlService {
  private apiUrl = environment.tinyURL + '/create';
  private apiKey = environment.tinyApiToken;

  constructor(private http: HttpClient) { }

  shortenUrl(longUrl: string): Observable<string> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.apiKey}`
    });

    const params = new HttpParams()
    .set('interceptorNotNeeded', 'true');

    const requestBody =  {
      "url": longUrl,
      "domain": environment.tinyURLDomain,
      "alias": "",
      "tags": "",
      "expires_at": "",
      "description": ""
    };

    return this.http.post<string>(this.apiUrl, requestBody, { params, headers });
  }
}
