import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { appConfig } from '../../../_core/config/app-config.const';
import { CommonService } from '../../../_services/common.service';

@Component({
  selector: 'tangram-comment-input-selection',
  templateUrl: './comment-input-selection.component.html',
  styleUrls: ['./comment-input-selection.component.scss']
})
export class CommentInputSelectionComponent implements OnInit {
  appConfig = appConfig;
  @Input() submitButtonText: string = 'Submit Comment'; // To store submit button text
  @Input() disableCommenting;
  @Input() isForum: boolean = false; //To check input selection called from forum or not
  @Output() submitComment = new EventEmitter(); // To emit event to send form data on form submission
  iconPath: string = appConfig.iconPath; // To store icon path from config
  form: FormGroup; // To represent comment form
  isStartedTyping: boolean = false;
  // To store comment types
  types = [
    { name: 'Comment', value: 'DISCUSS', img: 'discuss-dropdown.svg' },
    { name: 'Question', value: 'QUESTION', img: 'question-dropdown.svg' },
    // { name: 'Suggest', value: 'SUGGESTION', img: 'suggest-dropdown.svg' },
    // { name: 'Recommend a Resource', value: 'RECOMMEND', img: 'resource-dropdown.svg' },
  ];
  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService
  ) { }

  /**
   * Called when component being called
   */
  ngOnInit() {
    this.buildCommentForm(); // Build comment form
  }

  /**
   * Build comment form
   */
  buildCommentForm() {
    this.form = this.formBuilder.group({
      text: ['', [Validators.required]],
      type: [this.types[0].value, ''],
      timeSlot: [0]
    });
  }

  /**
   * Called when comment text gets changed
   * @param value
   */
  onChangeCommentText(value) {
    // Capture video time when user has started typing
    if (!this.isStartedTyping) {
      let player = this.commonService.player;
      this.form.patchValue({
        timeSlot: player.currentTime()
      });
    }
    this.isStartedTyping = value ? true : false;
  }

  /**
   * Called when form gets submitted
   * @param isFormValid isFormValid to check whether form is valid or not
   */
  submit(isFormValid: boolean) {
    let player = this.commonService.player;
    // Emit value to parent component when form gets submitted and validated
    if (isFormValid) {
      this.submitComment.emit(this.form.value); // Emit form data
      this.isStartedTyping = false;
      // Reset form
      this.form.reset();
      this.form.patchValue({ type: this.types[0].value });
    }
  }
}
