import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'tangram-laoder',
  templateUrl: './laoder.component.html',
  styleUrls: ['./laoder.component.scss']
})
export class laoderComponent implements OnInit {
   
  ngOnInit() {
 
  }
}
