import { TangramList } from './../../../../shared';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard.service';
import { Component, Input, OnInit, Renderer2, ViewChild, ElementRef, OnChanges, SimpleChanges, OnDestroy, Output, EventEmitter, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { CommonModalComponent } from 'src/app/shared/components/common-modal/common-modal.component';
import { CommonService } from 'src/app/_services/common.service';
import { appConfig } from 'src/app/_core/config/app-config.const';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { environment } from 'src/environments/environment';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { ToastrService } from 'ngx-toastr';
import { TinyUrlService } from '../../services/tinyurl.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'my-tangram',
  templateUrl: './my-tangram.component.html',
  styleUrls: ['./my-tangram.component.scss'],
  // host: {
  //   '(document:click)': 'onClick($event)',
  // },
})
export class MyTangramComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('AlertContent',  { static: true }) alertContent!: TemplateRef<any>;
  imgPath = appConfig.imagePath;
  iconPath = appConfig.iconPath;
  myTangramList: any[];
  showSideMenuBar: boolean = false;
  currentUser: any;
  placeholderImage: string = "assets/img/videos/img6.png";
  shareUrl: string = '';
  fbShareUrl: string = '';
  inAppShareUrl:string = '';
  whatsAppShareUrl: string = '';
  whatsAppShareUrl1: string = '';
  CurrentSharePathwayId: string = '';
  imgEnvPath = environment.backendUrl;
  private ngNavigatorShareService: NgNavigatorShareService;
  currentPathwayId = ''
  alterMessage = ''
  
  constructor(
    private modalService: NgbModal,
    private cd: ChangeDetectorRef,
    private renderer: Renderer2,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private dashboardService: DashboardService,
    ngNavigatorShareService: NgNavigatorShareService,
    private toasterService: ToastrService,
    private tinyUrlService: TinyUrlService,



    ) {
      this.currentUser = this.authService.currentUserValue;
      this.ngNavigatorShareService = ngNavigatorShareService;

    }

  /**
   * Called when input properties get changed
   * @param changes changes to be got
   */
  ngOnChanges(changes: SimpleChanges) {
    console.log('my-tangram input changes');
  }

  /**
   * Called when component being called
   */
  ngOnInit() {
    console.log('My Tangram Component Initialized');
    this.dashboardService.getMyTangramList().subscribe((response: TangramList<any[]>) => {
      this.myTangramList = response.data ? response.data.reverse() : [];
      this.myTangramList.forEach( (el, i) => {
        if (el.imageURL) {
          this.myTangramList[i].overviewImageUrl = `${environment.backendUrl}${el.imageURL}`;
        } else if (el.tempImageURL) {
          this.myTangramList[i].overviewImageUrl = el.tempImageURL;
        }
        else {
          this.myTangramList[i].overviewImageUrl = "assets/img/videos/img6.png";
        }
      })
      this.myTangramList = this.myTangramList.filter( (el, i) => el.totalVideos)
    });
    this.fbShareUrl = environment.fbShareUrl;
    let details = navigator.userAgent;
    this.inAppShareUrl = environment.inAppShareUrl;
    let regexp = /android|iphone|kindle|ipad/i;

    /* Using test() method to search regexp in details
    it returns boolean value*/
    let isMobileDevice = regexp.test(details);
    if (isMobileDevice) {
      this.whatsAppShareUrl=environment.whatsAppShareUrlMobile;
    } else {
      this.whatsAppShareUrl=environment.whatsAppShareUrl;
    }
    
  }

  /**
   * Toggle side menu bar
   */
  toggleSideMenuBar($event: Event) {
    console.log('inside toggleSideMenuBar');
    $event.stopPropagation();
    this.showSideMenuBar = !this.showSideMenuBar;
    // Hide show side menu bar based on status
    if (this.showSideMenuBar) {
      this.renderer.addClass(document.body, 'menu-open');
    }
    else {
      this.renderer.removeClass(document.body, 'menu-open');
    }
  }

  /**
   * Toggle side menu bar when click anywhere
   * @param event
   */
  onClick(event) {
    console.log('inside onClick event');
    if (this.showSideMenuBar) {
       this.toggleSideMenuBar(event);
    } else {
      this.renderer.removeClass(document.body, 'menu-open');
    }
  }

  /**
   * navigate to create a new tangram page
   */
  createTangram() {
    this.router.navigate(['/create-tangram']);
  }
  
  /**
   * navigate to create a new tangram page
   */
  navigateToPathwayDetails(pathway: any) {
    let pathwayId = pathway._id;
    this.router.navigate(['/dashboard', pathwayId]);
  }

  //logout user
  logout() {
    this.authService.logout();
    this.router.navigate(["/home"]);
  }

  /**
   * Called when component being destroyed
   */
  ngOnDestroy() {
    console.log('my-tangram component destroyed');
  }

  /**
 * redirects user to share popup
 * @param link to be shared
 */
  shareRedirect(appUrl,link) {
    var shareURL = appUrl + encodeURIComponent(link);
    window.open(shareURL,'popup','width=600,height=600'); 
    return false;
  }  

  copyLink(type){
    // const content = document.getElementById('share-tangram-url').textContent;
    // loading the content into our clipboard
    let link = this.shareUrl;
    navigator.clipboard.writeText(link);
  }

  async openSharePopup(pathwayId) {
    if(this.CurrentSharePathwayId && this.CurrentSharePathwayId !== pathwayId) {
      var popup = document.getElementById("share-popup" + this.CurrentSharePathwayId);
      var btnPopup = document.getElementById("shareBtn" + this.CurrentSharePathwayId);
      if(popup) {
        popup.classList.toggle("show");
      }
      if(btnPopup) {
        btnPopup.classList.toggle("visible");
      }
    }
    let link = environment.appRedirectUrl + '/#/dashboard/' + pathwayId
    this.shareUrl = await this.shortenUrlFunc(link);
    document.getElementById("share-tangram-url" + pathwayId).setAttribute('value', this.shareUrl);
    var popup = document.getElementById("share-popup" + pathwayId);
    var btnPopup = document.getElementById("shareBtn" + pathwayId);
    let isIOS = /iphone|ipad/i.test(navigator.userAgent);
    if(isIOS){
      this.ngNavigatorShareService.share({
        title: '',
        // text: 'Sharing My tangram',
        url: this.shareUrl
      }).then( (response) => {
        console.log(response);
      })
      .catch( (error) => {
        console.log(error);
      })    
    }else{
      if(popup) {
        popup.classList.toggle("show");
      }
      if(btnPopup) {
        btnPopup.classList.toggle("visible");
      }
    }
    if(this.CurrentSharePathwayId && this.CurrentSharePathwayId == pathwayId) {
      this.CurrentSharePathwayId = '';
    } else {
      this.CurrentSharePathwayId = pathwayId;
    }
  }

  /**
   * Open share modal
   * @param content content to be displayed as modal
   */
  openShareModal(content) {
    this.modalService.open(content, { centered: true }).result.then(() => {
    }, () => {
    });
  }

  /**
   * Update overview image url if given url is not valid
   * @param event
   */
  updateOverviewImageUrl(event, i) {
    this.myTangramList[i].overviewImageUrl = "assets/img/videos/img6.png";
  }

  async shortenUrlFunc(longUrl) {
    try {
      const response: any = await this.tinyUrlService.shortenUrl(longUrl).toPromise();
      return response.data.tiny_url; // Return the shortened URL
    } catch (error) {
      console.error('Error occurred while shortening URL:', error);
        this.toasterService.error(error)
        return longUrl;
      }
  }

  showPopup(pathwayId, modalContent: TemplateRef<any>) {
    if(!this.currentUser.subscriptionActive){
      this.modalService.open(modalContent, { centered: true, windowClass : 'unlock-premium' })
      return ;
    }
    this.currentPathwayId = pathwayId
    this.alterMessage = "Do you want to delete this tangram ?"
    this.modalService.open(this.alertContent, { centered: true, windowClass : 'unlock-premium' })
  }
  
  deleteTangram(){
    let data = {
      pathwayId : this.currentPathwayId
    }        
    this.dashboardService.deleteTangram(data).subscribe((res : any) => {
      if(res.success) {
        this.myTangramList = this.myTangramList.filter(t => t._id !== this.currentPathwayId)
        this.currentPathwayId = '';
      }
    })
  }
}
