import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CommonService } from '../../_services/common.service';
import { AuthService } from '../../_services/auth.service';

@Injectable()
export class HttpAPIInterceptor implements HttpInterceptor {
  private errorShown = false;
  constructor(
    private toasterService: ToastrService,
    private router: Router,
    private commonService: CommonService,
    private authService: AuthService) {
  }

  /**
   * Intercept request
   * @param req req to be modified
   * @param next next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestParams = req.params;
    // requestParams
    //Get auth token, pass it as bearer in reuest if its available
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    // Auth token available and header should be passed then pass the header
    // And If intercepter not needed then skip
    if (currentUser && (requestParams.get('interceptorNotNeeded') !== 'true' || req.url.includes('interceptorNotNeeded'))) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.token}`
        }
      });
    }

    //Handle the request and response
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // Show message in toaster
        if (typeof event.body.message === 'string' && event.body.message) {
          this.showError('success', event.body.message);
          // this.toasterService.success(event.body.message);
        }
      }
    },
      (error: any) => {
        if(requestParams.get('interceptorNotNeeded') == 'true' || req.url.includes('interceptorNotNeeded')){
          return;
        }
        // Show error message in toaster
        if (error.error && typeof error.error.message === 'string') {
          this.showError('error', error.error.message);
          // this.toasterService.error(error.error.message);
        }
        if (error.status == 0 || error.status == -1) {
          this.showError('error', "Server Not Responding");
          // this.toasterService.error("Server Not Responding");
        }
        else if (error.status == 401) {
          this.authService.logout();
          this.router.navigate(['login']);
        }
        else if (error.status == 403) {
          this.router.navigate(['unauthorized']);
        }
        else if (error.status == 404) {
          this.showError('error', "Resource You Have Requested Not Found," + " URL: " + error.url);
          // this.toasterService.error("Resource You Have Requested Not Found," + " URL: " + error.url);
        }
        else if (error.status == 412) {
          this.authService.saveLoggedInUser(error.error);
          // Screen name is required for the registration, so when user do social login
          // and user has not added screen name then redirect to registration page for entering screen name
          this.commonService.raiseEventToHideRegistrationControlsExceptScreenName(error.error.data);
          this.router.navigate(['/register']);
        }
      }));
  }

  showError(type: string, error: any): void {
    if(!this.errorShown){
      this.errorShown = true;
      if(type == 'success'){
        this.toasterService.success(error);
      }else if(type == 'error'){
        this.toasterService.error(error);
      }  
    }
    setTimeout(() => {
      this.errorShown = false;
    }, 1000); // 5 seconds delay, adjust as needed
  }
}
