import { AfterViewInit, ChangeDetectorRef, AfterContentChecked, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/_services/common.service';
declare let gtag: Function;

@Component({
  selector: 'tangram-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  isEditProfileSubScription: Subscription;
  addClassInDiv: boolean = true;
  isFooterVisible: boolean = true;

 
  constructor(private commonService: CommonService, private cdr: ChangeDetectorRef, private router: Router,) { 
    let user=JSON.parse(localStorage.getItem('currentUser'));
    if(user){gtag('event','page_visit', {
      'event_category': user._id,
      'event_label': this.router.url
    });}
     
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.isEditProfileSubScription = this.commonService.onChangeView.subscribe((param: any) => {
      this.addClassInDiv = param == 'edit-profile' ? false : true;
    });
    if (this.router.url === '/my-tangram' || this.router.url === '/configuration') {
      this.isFooterVisible = true;
    }
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.isEditProfileSubScription ? this.isEditProfileSubScription.unsubscribe() : '';
  }

}
