// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: "http://ec2-3-89-89-3.compute-1.amazonaws.com:3000/api/v1",
  // backendUrl: 'http://ec2-3-89-89-3.compute-1.amazonaws.com:3000/',
  baseUrl: "https://stagingapi.tangramio.com/api/v1",
  backendUrl: 'https://stagingapi.tangramio.com/',
  // baseUrl: "http://192.168.9.182:3000/api/v1",
  // backendUrl: 'http://192.168.9.182:3000/',
  // baseUrl: "https://api.tangramio.com/api/v1",
  // backendUrl: 'https://api.tangramio.com/',
  fbClientId: '413243388210149',
  googleClientId: '66628399254-ovi9mbgjruopc9gk8iov5afnn9esugmi.apps.googleusercontent.com',
  recaptchaSiteKey: '6LeG_5gaAAAAAMm9OA34nk8vbQxY_OGGn9tZPXhr', // Google Site Key to access recaptcha
  appleClientId:'com.tangram.service', // Apple client id
  appRedirectUrl: 'https://staging.tangramio.com', // Staging url
  appleRedirectUrl:'https://staging.tangramio.com', // Staging url
  // appleRedirectUrl:'https://tangramio.com' // Production url
  fbShareUrl: 'https://www.facebook.com/sharer/sharer.php?u=',
  whatsAppShareUrlMobile: 'https://api.whatsapp.com/send?text=',
  whatsAppShareUrl: 'https://web.whatsapp.com/send?text=',
  inAppShareUrl:'https://www.linkedin.com/shareArticle?mini=true&url=',
  youtubeSiteKey: 'AIzaSyD_DujENeqFwEGrFJzwKb5ehQT9F0tT4zM', // Site key to access youtube api
  proxyWorkerURL:'https://nytimes.tangram-devops.workers.dev',
  tinyURL:'https://api.tinyurl.com',
  tinyApiToken:'bH1vgzAr6LyiIpljnNWf9s0CeaUEFWOmRoWEsmvkaSmTx6K8JCAlYillDJPc',
  tinyURLDomain : 'tngrmio.com',
  googleMapsApiKey: 'AIzaSyBk6wBxAlifjh0qhHERNVOjdvd_DzAkexc'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
