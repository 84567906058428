import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { appConfig } from 'src/app/_core/config/app-config.const';
import { CommonService } from 'src/app/_services/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'unlockPremium-popup',
  templateUrl: './unlockPremium.component.html',
  styleUrls: ['./unlockPremium.component.scss']
})
export class unlockPremiumComponent implements OnInit {
  iconPath = appConfig.iconPath;
  imgPath = appConfig.imagePath;

  constructor(
    private commonService: CommonService,
    private modalService: NgbModal,
    private router: Router
    ) { }

  ngOnInit() { }
  
  navigateToPricing(){
    this.closeModal()
    // this.modalService.dismissAll()
    this.router.navigate(['/home/payment-page']);
  }
  closeModal(){
    this.modalService.dismissAll()
  }

}
