import { CreateTangramComponent } from './../dashboard/components/create-tangram/create-tangram.component';
import { MyTangramComponent } from './../dashboard/components/my-tangram/my-tangram.component';
import { MyBookmarkComponent } from './../dashboard/components/my-bookmarks/my-bookmarks.component'
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard.component';
import { ForumComponent } from './components/forum/forum.component';
import { QuestionDetailComponent } from './components/question-detail/question-detail.component';
import { LeaderboardComponent } from './components/leaderboard/leaderboard.component';
import { LayoutComponent } from '../../layouts/layout/layout.component';
import { AuthGuardGuard } from '../../_core/_guard/auth-guard.guard';
import { EditTangramComponent } from './components/edit-tangram/edit-tangram.component';
import { ShareTangramComponent } from './components/share-tangram/share-tangram.component';

const routes: Routes = [
  {
    path: 'dashboard/:pathwayId', component: LayoutComponent, children: [
      {
        path: '', component: DashboardComponent
      },
      {
        path: 'forum/:pathwayId', children: [
          { path: '', component: ForumComponent },
          { path: 'question-detail/:id', component: QuestionDetailComponent },
        ]
      },
      {
        path: 'leaderboard', children: [
          { path: '', component: LeaderboardComponent },
        ]
      }
    ]
  },
  {
    path: 'video/:id', component: LayoutComponent, children: [
      {
        path: '', component: DashboardComponent
      },
    ]
  },
  {
    path: '', component: LayoutComponent, canActivate: [AuthGuardGuard], children: [
      {
        path: 'my-tangram', component: MyTangramComponent
      },
    ]
  },
  {
    path: '', component: LayoutComponent, canActivate: [AuthGuardGuard], children: [
      {
        path: 'my-bookmarks', component: MyBookmarkComponent
      },
    ]
  },  
  {
    path: '', canActivate: [AuthGuardGuard], children: [
      {
        path: 'share-tangram', component: ShareTangramComponent
      },
    ]
  },
  {
    path: '',  children: [
      {
        path: 'create-tangram', component: CreateTangramComponent
      },
    ]
  },
  {
    path: '', canActivate: [AuthGuardGuard], children: [
      {
        path: 'edit-tangram-video/:videoId', component: CreateTangramComponent
      },
    ]
  },
  {
    path: '', canActivate: [AuthGuardGuard], children: [
      {
        path: 'edit-tangram/:pathwayId', component: EditTangramComponent
      },
    ]
  },
   //
  //{
  //  path: '', component: DashboardComponent
  //},
  //{
  //  path: 'forum/:pathwayId', children: [
  //    { path: '', component: ForumComponent },
  //    { path: 'question-detail/:id', component: QuestionDetailComponent },
  //  ]
  //},
  //{
  //  path: 'leaderboard', children: [
  //    { path: '', component: LeaderboardComponent },
  //  ]
  //}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
